@import '../../styles/propertySets.css';

.root {
  /* Layout */
  /*box-shadow: 0px 1px 5px 0px rgba(158, 158, 158, 1);*/
  /*margin: 5px 5px 20px;*/

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    /*margin: 0 20px 5px;*/
    height: 340px;
  }
}

.imageWrapper {
  width: 100%;
  height: 238px;
  border-radius: 5px;
  position: relative;
  background: var(--matterColorLightGray);
}

.checkbox {
  /*width: 29px !important;*/
  /*height: 26px !important;*/
  & svg {
  fill: none;
      width: 30px !important;
      height: 37px !important;
    }
}
@-moz-document url-prefix() {
  .checkbox {
    /*width: 25px;*/
    /*height: 22px;*/
  & svg {
      width: 29px !important;
      height: 25px !important;
    }
  }
}

.aspectWrapper {
  padding-bottom: 176px;
}

.imagePub {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  filter: drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.02)) drop-shadow(0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852)) drop-shadow(0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481)) drop-shadow(0px 20px 13px rgba(0, 0, 0, 0.01)) drop-shadow(0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185)) drop-shadow(0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481));
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px;

  @media (--viewportLarge) {
    width: 100%;
  }
}

.title {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: #4A4A4A;
  font-size: 16px;
  line-height: 19px;
  height: 65px;
  font-weight: var(--fontWeightBold);
  margin: 0;
  overflow: hidden;
}

.topInfoRow {
  display: flex;
  align-items: center;
}

.topInfoItem {
  width: 100%;
  font-weight: var(--fontWeightBold);
}

.categoryItem,
.locationItem,
.avatarItem {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightBold);
  display: flex;
  margin: 0;
}

.skitouringIcon {
  margin-right: 8px;
}

.skitouringIcon svg {
  height: 20px;
  width: 20px;
}

.avatarItem {
  display: flex;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  word-break: break-all;
}

.avatarImage {
  height: 20px;
  width: 20px;
  margin-right: 8px;
}

.locationName {
  color: #4A4A4A;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
}
.rowHostelDate {
  display: block;
  justify-content: space-between;
  @media (--viewportSmall) {
    display: flex;
  }
}

.dateInfoRow {
  /* Font */
  @apply --marketplaceH4FontStyles;
  font-size: 12px;
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
  color: var(--matterPlaceholder);
  letter-spacing: -0.02em;
  display: flex;
  margin: 0 0 6px;
}
.iconHostel {
  margin: 0 11px;
}

.priceLabel {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorLightGray);

  margin: 0 8px 0 0;
  font-size: 12px;
}

.priceValue {
  @apply --marketplaceTinyFontStyles;

  margin: 0;
}

.amenitiesInfoRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 46px 0;
}


.skillsInfoRow {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;

  @media (--viewportMedium) {
    flex-wrap: wrap;
  }

  @media (--viewportLarge) {
    flex-wrap: nowrap;
  }
}

.skillsInfoRowItem {
  @apply --marketplaceTinyFontStyles;

  display: flex;
  align-items: center;
  margin: 0;
  text-transform: uppercase;
  flex: 0 0 40%;
  max-width: 40%;
}

.skillsInfoRowItem svg {
  margin-right: 12px;
  width: 20px;
  height: 20px;
}

.imageButtonPrev,
.imageButtonNext {
  position: absolute;
  width: 30px;
  height: 33px;
  background: rgba(255, 255, 255, .54);
  border-radius: 4px;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  outline: none;
  cursor: pointer;
}

.imageButtonPrev {
  left: 0;
}

.imageButtonNext {
  right: 0;
}

.imageButtonPrev:after,
.imageButtonNext:after {
  content: '';
  position: absolute;
  border: 2px solid rgba(0, 0, 0, .7);
  border-width: 2px 0 0 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 10px;
  height: 10px;
}

.imageButtonPrev:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.imageButtonNext:after {
  transform: translate(-50%, -50%) rotate(135deg);
}

.shareLike {
  position: absolute;
  top: -3px;
  right: -8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 64px;
  height: 48px;
  z-index: 9;
  /*background: rgba(255, 255, 255, .5);*/
  transition: all .3s;
}

.shareButtonsHolder {
  position: absolute;
  left: 100%;
  width: 300px;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  border-radius: var(--borderRadius);

  @media (--viewportLarge) {
    height: 100%;
  }
}
