@import '../../styles/propertySets.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  /*--TabNav_linkWidth: 240px;*/
  /*--TabNav_linkBorderWidth: 4px;*/
}

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  @apply --marketplaceTabNavFontStyles;
  color: var(--matterColor);
  font-size: 18px;
  font-family: var(--fontFamily);
  line-height: 22px;
  letter-spacing: -0.04em;
  font-weight: var(--fontWeightRegular);
  padding-bottom: 10px;
  padding-top: 10px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    color: var(--matterColorDark);
  }

  @media (--viewportLarge) {
    /*font-size: 22px;*/
    border-bottom-width: 0px;
    margin-top: 0;
    padding-top: 6px; /* Drop the text to adjust it to correct baseline */
    padding-bottom: 2px;
    padding-right: 50px;
    width: 100%;
  }
}

.selectedLink {
  /*color: var(--matterColorDark);*/
  @media (--viewportLarge) {
    /*width: var(--TabNav_linkWidth);*/
    /*background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");*/
    /*background-position: right center;*/

    /*&:hover {*/
      /*background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");*/
      /*background-position: right center;*/
    /*}*/
  }

  @media (--viewportLarge) {
    /*border-right: 5px solid var(--matterColorDark);*/
  }
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}
