@import '../../styles/customMediaQueries.css';

.heading {
  margin: 5px 0 18px 0;

  @media (--viewportMedium) {
    margin: 8px 0 24px 0;
  }
}

.wrapperContent {
  @media (--viewportLarge) {
    padding-top: 60px;
    padding-left: 40px;
    border: none;
  }
}

.wrapperSideNav {
  @media (--viewportLargeWithPaddingsSearch) {
    padding: 60px 25px 82px calc((100% - 1310px) / 2);
    background: var(--matterColorLight);
    & nav {
        padding: 30px 40px 38px 30px;
        margin-top: 0;
        background: #FAFAFA;
        box-shadow: 0 100px 80px rgba(0, 0, 0, 0.02), 0 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0 20px 13px rgba(0, 0, 0, 0.01), 0 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
        border-radius: 10px;
      }
  }
}

.content {
  @media (--viewportLarge) {
    max-width: 925px;
    width: 925px;
  }
  & h1,
  & h2 {
      font-size: 17px;
      font-weight: bold;
      line-height: 21px;
      margin: 21px 0 0 0;
    }
  & p,
  & li {
      margin: 0;
      font-size: 13px;
      line-height: 19px;
      font-weight: normal;
      padding-top: 11px;
    }


& :global(ul) {
      list-style: disc;
       padding-left: 10px;
    }

  & :global(#california_info){
    padding-top: 80px;
  }

  & :global(#brazil_info){
    padding-top: 80px;
  }
}
