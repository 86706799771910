@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  background: url('../../assets/heroSmall.png');
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  @media (--viewportMedium) {
    background: url('../../assets/heroImg.png');
  }
}

/*.root:after {*/
/*  content: '';*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  background: var(--matterColorDark);*/
/*  opacity: .6;*/
/*  z-index: 0;*/
/*}*/

.root > * {
  z-index: 1;
}

.heroContent {
  margin: 0 20px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }
  @media (--viewportLargeWithPaddings) {
    margin: 0 auto;
    max-width: calc(954px);
    width: 100%;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  display: none;
  font-weight: var(--fontWeightBold);
  letter-spacing: -1.07px;
  padding-right: 33px;
  color: var(--matterColorLight);
  font-size: 30px;
  line-height: 35px;
  composes: animation;
  margin-bottom: 30px;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    display: block;
    padding-right: 0;
    text-align: center;
    line-height: 48px;
    font-size: 48px;
  }
}
.heroMainTitleMobile {
  @apply --marketplaceHeroTitleFontStyles;
  display: block;
  font-weight: var(--fontWeightBold);
  letter-spacing: -0.3px;
  padding-right: 33px;
  color: var(--matterColorLight);
  font-size: 30px;
  line-height: 35px;
  composes: animation;
  margin-bottom: 30px;
  animation-delay: 0.5s;
  @media (--viewportMedium) {
    display: none;
    padding-right: 0;
    text-align: center;
    line-height: 48px;
    font-size: 48px;
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;
  font-weight: 500;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;

  animation-delay: 0.8s;

  @media (--viewportLarge) {
    display: block;
    width: 260px;
  }
}

.activityHolder {
  width: 100%;
  height: 80px;
  margin-bottom: 13px;
  @media (--viewportLargeWithPaddings) {
    width: 346px;
  }
}
.heroSelectLabel {
  outline: none;
  padding: 10px 0;
}
.labelActivities {
  font-size: 12px;
  display: flex;
  padding-left: 20px;
  justify-content: flex-start;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;
  color: var(--matterPlaceholder);
}

.heroSelect {
  display: block;
  padding: 0;
  background: #fff;
  border: .5px solid #979797;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  width: 100%;
  margin-bottom: 15px;
  @media (--viewportMedium) {
    height: 100%;
    margin-bottom: 0;
  }
}

.selectedValueWrapper {
  display: flex;
  padding: 4px 0 19px 21px;
  line-height: 30px;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  letter-spacing: -0.04em;
  color: #4A4A4A;
}
.selectedValueWrapper svg {
  max-width: 27px;
  margin-right: 11px;
  max-height: 26px;
}

.heroForm {
  /*background-color: rgba(255,255,255,0.2);*/
  border-radius: 5px;
@media (--viewportLargeWithPaddings) {
  display: flex;
  justify-content: space-between;
  height: 80px;
}
}

.submitButton {
  display: flex;
  width: 224px;
  height: 60px;
  font-size: 18px;
  line-height: 20px;
  font-weight: var(--fontWeightBold);
  border-radius: 10px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  background-color: var(--matterTomato);
  @media (--viewportLargeWithPaddings) {
    margin: 0;
    height: 100%;
  }
}

.submitButton span {
  text-align: center;
  color: var(--matterColorLight);
}

.submitButton:hover {
  text-decoration: none;
}

.dateSwitchHolder {
  display: flex;
  justify-content: flex-end;
  height: 70px;
  padding: 15px;
  line-height: 40px;
  font-size: 12px;
  border-bottom: 1px solid var(--matterColorAnti);
}

.dateSwitch {
  margin-left: 30px;
  height: 100%;
  width: 150px;
  border: 1px solid var(--matterColorAnti);
  border-radius: 4px;
  position: relative;
  padding: 0 10px;
}

.dateSwitch:hover {
  background: var(--matterColorAnti);
}

.dateSwitch button {
  border-radius: 50%;
  background: rgba(0,0,0,.2);
  width: 20px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  padding: 0;
  border: 0;
  outline: none;
  opacity: .5;
}

.dateSwitch button:after {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  border: solid 1px black;
  border-width: 0 0 1px 1px;
  top: 50%;
  left: 50%;
}

.dateSwitch button:first-child {
  right: 30px;
}

.dateSwitch, .dateSwitch button, .dateSwitch button:after {
  transition: all .3s;
}

.dateSwitch button:hover:after {
  border-color: blue;
}

.dateSwitch button:hover {
  background: var(--matterColorLight);
  opacity: 1;
}

.dateSwitch button:first-child:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.dateSwitch button:last-child:after {
  transform: translate(-50%, -50%) rotate(-135deg);
}

.navigationDates {
  height: 40px;
  width: 40px;
  background: var(--matterColorLight);
  border-radius: 50%;
  top: 50%;
}

.clearButtonHolder {
  border-top: 1px solid var(--matterColorAnti);
}
.titleStyle {
  position: absolute;
  font-size: 12px;
  z-index: 1;
  margin-top: 11px;
  padding-left: 20px;
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
  color: var(--matterPlaceholder);
}

.monthSwitchL, .monthSwitchR {
  display: inline-block;
  width: 35px;
  height: 35px;
  background: var(--matterColorLight);
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  position: relative;
}

.monthSwitchL:after, .monthSwitchR:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border: 5px solid red;
  border-width: 1px 1px 0 0;
  top: 50%;
  left: 50%;
}

.monthSwitchL:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.monthSwitchR:after {
  transform: translate(-50%, -50%) rotate(-135deg);
}
