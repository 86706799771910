@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;

  width: 100%;

  @media (--viewportSmall) {
    width: auto;
  }
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 5px;

  width: 100%;

  @media (--viewportSmall) {
    width: auto;
  }

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 5px;
  background-color: #296151;
  border: 1px solid #296151;

  width: 100%;

  @media (--viewportSmall) {
    width: auto;
  }

  &:hover,
  &:focus {
     border: 1px solid #239954;
     background-color: #239954;
  }
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 0;
  padding-bottom: 30px;
  border: none;
}

.list {
  margin: 0;
}

.item {
  padding: 2px 0;
  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;
  & span {
      font-size: 16px;
      line-height: 30px;
      color: var(--matterColor);
      font-weight: var(--fontWeightRegular);
      letter-spacing: -0.04em;
    }

  @media (--viewportMedium) {
    padding: 7px 0;
  }
}
