@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;
  margin-top: 60px;
  justify-content: flex-start;

  @media (--viewportMedium) {
    margin-top: 160px;
    /*justify-content: space-between;*/
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -1px;
}


.redirectTo {
  line-height: 30px;
  font-size: 18px;
  margin-top: 30px;
  font-weight: var(--fontWeightRegular);

  & span {
      letter-spacing: -0.04em;
      color: var(--matterTomato);
  }
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.submitButton {
  @apply --marketplaceButtonStylesPrimary;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}
