@import '../../styles/propertySets.css';
.root {
  width: 100%;
  height: 100%;
}

.sectionBoard {
  object-fit: cover;
  height: 427px;
  position: relative;
  width: 100%;
  & .sectionContent {
      padding-left: 15px;
      padding-top: 75px;
      margin-top: 0;
      width: 100%;
    @media (--viewportLarge) {
      padding-top: 184px;
      max-width: 1240px;
      margin: 0 auto;
    }
  }
  @media (--viewportLarge) {
    height: 600px;
  }
}
.sectionBoardImg {
  display: none;
  top: 0;
  z-index: -2;
  position: absolute;
  max-width: 100%;
  height: 427px;
  object-fit: cover;
  overflow: hidden;
  @media (--viewportSmall) {
    display: block;
  }
  @media (--viewportLarge) {
    height: 600px;
  }
}
.sectionBoardImgSmall {
  display: block;
  top: 0;
  z-index: -1;
  position: absolute;
  max-width: 100%;
  height: 427px;
  object-fit: cover;
  @media (--viewportSmall) {
    display: none;
  }
}
.sectionBoardTitle {
  line-height: 26px;
  font-size: 20px;
  max-width: 235px;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightBold);
  @media (--viewportLarge) {
    font-size: 48px;
    line-height: 48px;
    max-width: 100%;
  }
}
.sectionBoardText {
  font-size: 18px;
  line-height: 24px;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColorLight);
  max-width: 282px;
  @media (--viewportLarge) {
    line-height: 30px;
    max-width: 455px;
  }
}

.sectionPartners {
  margin: 44px auto 12px;
  @media (--viewportLarge) {
    margin: 100px auto 60px;
  }
}
.titleSectionPartners {
  font-size: 20px;
  line-height: 26px;
  padding-left: 10px;
  color: var(--matterColorDark);
  font-weight: var(--fontWeightBold);
  @media (--viewportSmall) {
    padding-left: 0;
    text-align: center;
  }
  @media (--viewportMedium) {
    font-size: 48px;
    line-height: 48px;
  }
}
.blockImgPartners {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 954px;
  & img {
      width: 100px;
      margin-right: 23px;
      @media (--viewportSmall) {
        width: 126px;
        height: 100%;
      }
      @media (--viewportLarge) {
        margin-right: 200px;
      }
      &:last-child {
         margin-right: 0;
       }
    }
}

.createListingButton {
  @apply --marketplaceButtonStyles;
  min-height: 47px;
  border-radius: 5px;
  line-height: 20px;
  letter-spacing: -0.1px;
  padding: 13px 0;
  height: 47px;
  width: 252px;
  /*@media (--viewportMedium) {*/
  /*  display: none;*/
  /*}*/
}
