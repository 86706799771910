.root {
  width: 100%;
  height: 100%;
}
.rootForm {
  position: relative;
  width: 100%;
}
.rootImg {
  position: absolute;
  top: 43%;
}

.sectionDiscount {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  & .sectionMount {
      width: 100%;
    @media (--viewportMediumSmall) {
      width: 50%;
    }
  }
  & .sectionMap {
      width: 100%;
    & iframe {
        width: 100%;
        border: none;
        overflow: hidden;
        height: 100%;
      }
    @media (--viewportMediumSmall) {
      width: 51%;
    }
  }
  @media (--viewportMediumSmall) {
    height: 496px;
    flex-direction: row;
  }
}

.modalForm {
  & iframe {
      width: 100%;
      border: none;
      height: 100%;
    }
}

.sectionMount {
  position: relative;
  background-image: url("./img/discountImgSmall.png");
  background-size: cover;
  object-fit: cover;
  display: flex;
  overflow: hidden;
  height: 226px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & .textUpTo {
      left: 44%;
      top: 6%;
      font-style: italic;
      font-size: 60px;
      color: var(--matterTomato);
      font-family: 'Inria Serif', serif;
    @media (--viewportSmall) {
      left: 44%;
      top: 26%;
      font-size: 50px;
    }
  }
  & .countDiscount {
      font-size: 100px;
      font-family: 'Source Serif Pro', serif;
      color: var(--matterColorLight);
    @media (--viewportSmall) {

    }
  }
  & .textDiscount {
      margin-top: 5px;
      margin-bottom: 0;
      font-style: italic;
      font-size: 40px;
      color: var(--matterTomato);
      font-family: 'Inria Serif', serif;
    @media (--viewportSmall) {
      font-size: 50px;
    }
  }
  & .forLetter {
      margin-top: 5px;
      text-align: center;
      font-style: italic;
      font-size: 40px;
      line-height: 35px;
      color: var(--matterTomato);
      font-family: 'Inria Serif', serif;
    @media (--viewportSmall) {
      margin-top: 10px;
      line-height: 42px;
      font-size: 50px;
    }
  }
  @media (--viewportSmall) {
    background-image: url("./img/mountDesktop.png");
    height: 496px;
  }
}
.mountMobile {
  position: absolute;
  display: block;
  @media (--viewportMedium) {
    display: none;
  }
}
.mountDesktop {
  position: absolute;
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}
.sectionMap {
  position: relative;
  width: auto;
  margin: 0;
  background-size: cover;
  display: flex;
  height: 496px;
  flex-direction: column;
  background-position: 1%;
  max-width: 100%;
  background-image: url("./img/worldMap.png");
  & .titleMap {
      font-size: 20px;
      color: #000000;
      line-height: 26px;
      font-weight: var(--fontWeightBold);
    @media (--viewportSmall) {
      font-size: 36px;
      /*margin: 30px 0 0 0;*/
      line-height: 42px;
    }
    @media (--viewportLargeWithPaddingsSearch) {
      font-size: 36px;
      margin: 0;
      line-height: 42px;
    }
  }
  & .subTitleMap {
      margin: 0 0 5px 0;
      font-size: 14px;
      color: #000000;
      line-height: 26px;
      font-weight: var(--fontWeightRegular);
    @media (--viewportSmall) {
      margin: 0 0 32px 0;
      font-size: 18px;
    }
  }
  @media (--viewportSmall) {
    margin: 0;
    align-items: center;
  }
  @media (--viewportLarge) {
    height: auto;
  }
}
.ImgMapItem {
  position: absolute;
}

.viewTours {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}
.viewToursSmall {
  display: block;
  @media (--viewportMedium) {
    display: none;
  }
}

.sentInputForm {
  margin-bottom: 10px;
}

.mapInputTours {
  position: relative;
  padding: 10px 27px 21px;
  background: var(--matterColorLight);
  border: 1px solid #000000;
  border-radius: 5px;
  margin-bottom: 10px;
  max-height: 80px;
  height: 80px;
  width: 335px;
  & label {
      color: #9E9E9E;
      font-size: 12px;
      line-height: 18px;
      padding: 0;
      font-weight: var(--fontWeightRegular);
    }
  & input {
      padding-top: 5px;
      font-size: 18px;
      line-height: 30px;
      font-weight: var(--fontWeightRegular);
      border-bottom: 0;
    @media (--viewportLargeWithPaddingsSearch) {
      padding-left: 64px;
    }
    &::placeholder {
       color: #4A4A4A;
     }
  }
  & span {
      display: none;
    }
  @media (--viewportSmall) {
    margin-bottom: 30px;
    width: 466px;
  }
}

.imgInputSection {
  left: 46px;
  top: 38px;
  width: 29px;
  height: 21px;
  display: none;
  @media (--viewportLargeWithPaddingsSearch) {
    display: block;
    position: absolute;
  }
}

.modalMailBlock {
  & button {
      border-radius: 5px;
    }
}
