@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceDefaultFontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0 0 15px 0;
  color: #101010;
  font-size: 20px;
  line-height: 24px;
  @media (--viewportMedium) {
    padding: 0 30px;
  }
  @media (--viewportLargeWithPaddingsSearch) {
    padding: 0;
  }
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin: 0 0 40px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.location {
  width: 100%;
  margin-top: 25px;
  position: relative;
  z-index: 10;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin:  0 40px 0 0;
    padding-right: 20px;
    overflow: hidden;
    & :last-child {
        padding-right: 0;
      }
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  transition: var(--transitionStyleButton);
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%);

  /* 13:6 Aspect Ratio */
  @media (--viewportMedium) {
    padding-bottom:  200px;
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: var(--boxShadowFavoritesPageListings);
  object-fit: cover;
}

.linkText {
  position: absolute;
  bottom: 12px;
  left: 0;
  right: 0;
  padding: 0 24px;
  text-transform: capitalize;

  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColorLight);
  text-shadow: var(--textShadow);
}

.sliderPrevArrow,
.sliderNextArrow {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  width: 35px;
  height: 35px;
  background: var(--matterColorLight);
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  outline: none;
  box-shadow: var(--boxShadowSliderArrows);
  border-radius: var(--borderRadiusCircle);
}

.sliderPrevArrow {
  left: 15px;

  @media (--viewportLarge) {
    left:  35px;
  }
}

.sliderNextArrow {
  right: 15px;

  @media (--viewportLarge) {
    right:  35px;
  }
}

.sliderNextArrow:after {
  content: '';
  position: absolute;
  border: 2px solid var(--matterColorDark);
  border-width: 2px 2px 0 0;
  top: 50%;
  left: 46%;
  width: 10px;
  height: 10px;
}
.sliderPrevArrow:after {
  content: '';
  position: absolute;
  border: 2px solid var(--matterColorDark);
  border-width: 2px 2px 0 0;
  top: 50%;
  left: 54%;
  width: 10px;
  height: 10px;
}

.sliderNextArrow:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.sliderPrevArrow:after {
  transform: translate(-50%, -50%) rotate(-135deg);
}

.slickTrack {
  width: 100%;
}

.noImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--matterColorNegative);
}

.noImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.noImageIcon {
  padding-top: 24px;
  box-sizing: content-box;
  stroke: var(--matterColor);
}

.noImageText {
  @apply --marketplaceH6FontStyles;
  color: var(--matterColor);
  padding-bottom: 24px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom:  0;
  }
}

.noImageLinkText {
  color: black;
  position: absolute;
  bottom: 12px;
  left: 0;
  right: 0;
  padding: 0 24px;
  text-transform: capitalize;

  @apply --marketplaceDefaultFontStyles;
  text-shadow: var(--textShadow);
}

.pdfIcon {
  box-sizing: content-box;
  stroke: var(--matterColor);
}

.pre {
  text-transform: lowercase;
}
