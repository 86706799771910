@import '../../styles/propertySets.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: 100%;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    /*margin-bottom: 30px;*/
  }
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 2px;
}

.bookingHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 0 84px 0;

  @media (--viewportMedium) {
    margin: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }

& :global(.DateRangePickerInput){
    box-shadow: none;
    height: 41px;
    background: none;
  }
}

.bookingDatesSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: relative;
  top: 0;
  /* left: -20px; */
  width: 100%;
  padding: 18px 21px 20px;
  background-color: var(--matterColorLight);
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.02), 0 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0 20px 13px rgba(0, 0, 0, 0.01), 0 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);
  @media (--viewportMedium) {
    justify-content: center;
    box-shadow: var(--boxShadowTop);
    padding: 7px 21px 0;
    left: 0;
    top: 89%;
    bottom: 0;
    position: fixed;
  }
  @media (--viewportLargeWithPaddingsSearch) {
    display: none;
  }

  @media(--viewportSmall) {
    width: calc(100% + 20px);
  }
}
.priceValueBlock {
  display: flex;

  @media (--viewportMedium) {
    align-items: center;

  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  flex-shrink: 0;
  margin-right: 19px;
  padding: 5px 0;
  @media (--viewportMedium) {
    align-items: center;
  }
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterTomato);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
  margin: 8px 0 0 5px;
  @media (--viewportMedium) {
    margin: 8px 0 0 5px;
  }
}

.bookButton {
  @apply --marketplaceButtonStylesPrimary;
  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
  display: flex;
  height: 48px;
  min-height: 48px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 20px;
  @media (--viewportMedium) {
    height: 50px;
    min-height: 50px;
  }
}
.lowWikiText {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 5px 0 0;
  letter-spacing: -0.02em;
  color: var(--matterPlaceholder);
  font-weight: var(--fontWeightRegular);
  font-size: 12px;
  line-height: 18px;
  @media (--viewportLarge) {
    margin: 15px 0 0;
  }
}
.lowWikiTextBolt {
  margin-left: 4px;
  font-weight: var(--fontWeightBold);
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.stickyModal {
  display: none;
  position: sticky;
  top: 110px;
  padding: 20px 30px 20px;
  background: var(--matterColorLight);
  border-radius: 10px;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
  @media (--viewportLarge) {
    display: block;
  }
}

.headingOfInfo {
  color: #101010;
  font-size: 12px;
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
  letter-spacing: -0.02em;
  margin: 10px 10px 0 0;
  @media (--viewportMedium) {
    margin: 0 10px 0 0;
  }
  @media (--viewportLarge) {
    margin: 10px 10px 15px 0;
  }
}

.headingOfInfoMargin {
  margin: 0;
}

.fromValueNumber {
  font-size: 24px;
  line-height: 32px;
  color: var(--matterTomato);
  font-weight: var(--fontWeightBold);
}

.bigNumber {
  font-size: 19px !important;
}
.veryBigNumber {
  font-size: 17px !important;
}

.perDay {
  flex: 0 0 60px;
  max-width: 60px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;
  font-size: 20px;
  line-height: 32px;
  margin-right: 2px;
  font-weight: var(--fontWeightBold);
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 2px;
    margin-bottom: 0;
  }
}
