.checkMark {
  display: block;
  width: 20px;
  height: 20px;
  border: solid 2px #757575;
  margin-right: 5px;
  border-radius: 3px;
  position: relative;
}
.checkMarkChecked {
  display: block;
  width: 20px;
  height: 20px;
  border: solid 2px #757575;
  margin-right: 5px;
  border-radius: 3px;
  position: relative;
}
.checkMarkChecked:after {
  content: '';
  position: absolute;
  height: 10px;
  width: 6px;
  border: solid 1px #757575;
  border-width: 0 1px 1px 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%) rotate(45deg);
}

