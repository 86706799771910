@import '../../styles/propertySets.css';

.hostActivityButton {
  border-radius: 5px;
  padding: 12px 14px;
  cursor: pointer;
  border: 2px solid var(--matterTomato);
  font-weight: var(--fontWeightBold);
  outline: none;
  letter-spacing: -0.04em;
  color: var(--matterTomato);
  width: 100%;
  line-height: 20px;
  text-align: center;

  @media (--viewportMedium) {
    width: auto;
  }

  &:hover {
    text-decoration: none;
  }
}

.hubspotWrapper {
  /* padding-bottom: 100px; */
}

.hubspotWrapper iframe {
  min-width: 100%;
}


.container {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  border-radius: 10px;
  padding: 90px 20px 30px;
  border-bottom: none;

  width: 100%;
  max-width: 820px;

  @media (--viewportMedium) {
    flex-basis: 820px;
    min-height: auto;
    height: auto;
    padding: 30px;
  }

  & > button {
    padding: 15px;
    top: 75px;
    right: 8px;

    @media (--viewportMedium) {
      padding: 30px;
      top: 0;
      right: 0;
    }
  }
}

.successForm {
  text-align: center;
}

.successFormTitle {

}

.successFormDescription {

}
