@import '../../styles/propertySets.css';

.root {
  outline: none;
}

.contentWrapper {
  @media (--viewportMedium) {
    margin-bottom: 24px;
  }
}

.buttonsWrapper {
  display: flex;
  padding: 0 20px 20px 22px;
}

.clearButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterPlaceholder);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.cancelButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterPlaceholder);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.submitButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterTomato);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
    transition: width var(--transitionStyleButton);
  }
}
