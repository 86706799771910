.root {
  position: relative;
  width: 100%;
  height: 370px;

  @media (--viewportLargeWithPaddings) {
    height: 560px;
  }
}

.img {
  overflow: hidden;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.isShowInLaptop {
  /*display: none;*/
  /*@media (--viewportLargeWithPaddings) {*/
  /*  display: block;*/
  /*}*/
}

.textWrapperImg {
  width: 90%;
  position: absolute;
  top:45%;
  left:50%;
  transform:translate(-50%, -50%);
  text-align: center;
  max-width: 820px;

  @media (--viewportMedium) {
    width: 100%;
  }
}

.topTextImg {
  font-family: var(--fontFamily);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightBold);
  margin-bottom: 16px;
}

.subTextImg {
  font-family: var(--fontFamily);
  font-size: 30px;
  line-height: 35px;
  margin: 0;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightBold);
  @media (--viewportLargeWithPaddings) {
    font-size: 48px;
    line-height: 48px;
  }
}
.nextText {
  font-family: var(--fontFamily);
  /*margin-top: 16px;*/
  font-size: 30px;
  line-height: 35px;
  margin: 0;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightBold);
  @media (--viewportLargeWithPaddings) {
    font-size: 48px;
  }
}

