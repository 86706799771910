@import '../../styles/propertySets.css';

.heading {
  margin: 5px 0 18px 0;

  @media (--viewportMedium) {
    margin: 8px 0 24px 0;
  }
}

.content {
  @media (--viewportLarge) {
    max-width: 563px;
  }
}

.bottomText {
  margin-top: 30px;
}

.addressHolder {
  margin-bottom: 20px;
}

.addressHolder p {
  margin: 0;
}

.dataHolder {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--matterColorNegative);
}

.dataLabel {
  flex: 0 0 40%;
  max-width: 40%;
  margin-right: 20px;
}

.dataText {
  flex: 0 0 calc(60% - 20px);
  max-width: calc(60% - 20px);
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  & > h1 {
      font-weight: var(--fontWeightBold);
      font-size: 30px;
      line-height: 35px;
      letter-spacing: -0.03em;
      color: var(--matterColorDark);
    }
  & p {
      font-weight: var(--fontWeightRegular);
      font-size: 20px;
      line-height: 34px;
      color: var(--matterColorDark);
    }

  @media (--viewportMedium) {
    /*width: calc(100% - 72px);*/
    width: 786px;
    margin: 72px auto 30px;
  }
}

.wrapperForm {

}

body :global(.hbspt-form) {
  width: calc(100% - 50px);
  margin: 0 auto;


& > h1 {
    font-weight: var(--fontWeightBold);
    font-size: 30px;
    line-height: 35px;
    letter-spacing: -0.03em;
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    width: 786px;
    /*margin: -460px auto 30px;*/
  }

  @media (--viewportLargeWithPaddings) {

    /*margin: -280px auto 30px;*/
  }
}

.contentText {
  max-width: 750px;
}

.wrapperImage {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;



  @media (--viewportMedium) {
    width: 650px;
  }



  & h4 {
      font-size: 18px;
      line-height: 21px;
      font-weight: var(--fontWeightBold);
    }

  &.titleImageContact {
    font-size: 30px;
    line-height: 35px;
    font-weight: var(--fontWeightBold);
    /*@media (--viewportLargeWithPaddingsSearch) {*/
    /*  width: 1280px;*/
    /*}*/
   }

  @media (--viewportLargeWithPaddingsSearch) {
    width: 1280px;
  }
}

.lineBlockImg {
  margin-bottom: 26px;

  @media (--viewportLargeWithPaddingsSearch) {
    display: flex;
  }
}

.itemImg {
  width: 100%;
}

.wrapperImg {
  margin: 0 auto 30px;
  width: 100%;

  @media (--viewportLargeWithPaddingsSearch) {
    width: 610px;
    max-width: 610px;
    height: 290px;
    margin: 30px 0 0;
    &:first-child {
       margin-right: 20px;
     }
  }
}

.linkText {
  color: var(--matterColor);
}

