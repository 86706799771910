.root {
  width: 100%;
  height: 100%;
  background-color: var(--matterColorNegative);
}

.mapRoot {
  width: 100%;
  height: 100%;
  order: 2;
}

.mapHolder {
  display: flex;
  flex-wrap: wrap;
  height: 640px;

  @media (--viewportLarge) {
    flex-wrap: nowrap;
  }
}

.surraundedBlockHolder {
  display: flex;
}

.infoPlaces {
  order: 1;
  padding: 10px 10px 10px 19px;
}

.mapWrapper {
  order:2;
}

.icon {
  width: 10px;
  height: 10px;
}

.titleHolder {
  display: flex;
  align-items: center;
}

.titleIcon {
  width: 17px;
  height: auto;
}

.title {
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-left: 6px;
  line-height: 15px;
  margin-top: 5px;
  color: #c1c1c1;
  font-weight: 400;
}

.rating {
  font-size: 13px;
  margin-right: 6px;
  margin-top: 5px;
  font-weight: 400;
}

.mobileReviewRating {

}

.reviewRatingStar {
  width: 12px;
  height: 12px;
}

.ratingHolder {
  font-size: 13px;
  font-weight: 400;
  margin-left: 23px;
}

.ratingTotal {
  font-size: 13px;
  margin-right: 6px;
}

.type {
  margin-top: 5px;
  margin-right: 6px;
  text-transform: capitalize;
}

.dataHolder {
  font-size: 14px;
  margin-top: 5px;
  font-weight: 400;
  margin-left: 23px;
}

.address {
  margin-left: 6px;
}
