@import '../../styles/propertySets.css';

.pageTitle {
  text-align: left;
  margin-bottom: 20px;
  margin-top: 10px;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1274px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 60px auto;
  }
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}
.imgText {
  top: 50%
}
.contentSide {
/*border-right: 1px solid var(--matterColorNegative);*/
  @media (--viewportMedium) {
    width: 285px;
    min-width: 285px;
    padding: 15px 30px 27px 30px;
    margin-right: 64px;
    margin-top: 0;
    height: 100%;
    background: #FAFAFA;
    min-height: 200px;
    position: sticky;
    top: 120px;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
    border-radius: 10px;
  }
  & > ul {
      margin-top: 0 !important;
    }
}

.contentMain {
  color: var(--matterColorDark);
  width: 100%;
  @media (--viewportMedium) {
    width: 90%;
    max-width: 925px;
    width: 925px;
  }

  & a {
      color: var(--matterColorDark);
    }
  & p {
      font-size: 20px;
      line-height: 34px;
      letter-spacing: 0em;
      margin: 0 0 30px;
      font-weight: var(--fontWeightRegular);
      color: var(--matterColorDark);
    }
  & li {
      font-size: 20px;
      line-height: 34px;
      letter-spacing: 0;
      margin: 0 0 30px;
      font-weight: var(--fontWeightRegular);
      color: var(--matterColorDark);
    }
  & > h2 {
      margin-top: 0 !important;
      color: var(--matterColorDark);
    }
  & h2 {
      margin: 30px 0;
      font-size: 30px;
      line-height: 35px;
      font-weight: var(--fontWeightBold);
      letter-spacing: -0.03em;
      color: var(--matterColorDark);
    }
  & h3 {
      font-size: 20px;
      margin: 30px 0 0;
      line-height: 26px;
      font-weight: var(--fontWeightBold);
      letter-spacing: -0.02em;
      color: var(--matterColorDark);
    }
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin-bottom: 16px;
  margin-top: 0;
  display: block;
  font-size: 18px;
  line-height: 22px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: -0.04em;
  color: var(--matterColor);
}

.title {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 15px;
  margin-top: 20px;
  width: 100%;
  display: block;
  font-weight: var(--fontWeightBold);
  letter-spacing: -0.04em;
  color: var(--matterColorDark);

  &:hover {
     color: var(--matterColorDark);
   }
}

.selected {
  color: var(--matterTomato);
  /*border-right: 5px solid var(--matterColorDark);*/
}

.navList {
  display: flex;
  flex-direction: column;
  /*margin-top: 3px;*/
  height: 100%;
  height: calc(100vh - 160px);
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar: none;
  /*padding-bottom: 20px;*/
  /*scrollbar-color: var(--marketplaceColor) #C2D2E4;*/
  scrollbar-color: rgba(255, 255, 255, 0.0) rgba(255, 255, 255, 0.0);

  &::-webkit-scrollbar {
     width: 0;
     height: 0;
   }

  @media (--viewportMedium) {
    & li:nth-child(11) {
      padding-right: 60px;
    }
  }
}

.navItem {

}

.list {
  /*padding-left: 20px;*/
  /*list-style-type: disc;*/
  font-size: 20px;
  line-height: 34px;
  font-weight: var(--fontWeightRegular);
  margin-top: 10px;
  margin-bottom: 30px;
}

.list li {
  font-size: 20px;
  line-height: 34px;
  letter-spacing: -0.01em;
  margin-bottom: 7px;
}

.listNumbers {
  list-style: decimal;
  padding-left: 30px;
  & li {
      font-size: 20px;
      line-height: 34px;
      font-weight: var(--fontWeightRegular);
    }
}

.customMarginBottom {
  margin-bottom: 0 !important;
}
.customMarginTop {
  /*margin-left: 10px;*/
  margin-top: 0 !important;
}
.imgBlock {
  height: 250px;
  margin-bottom: 30px;
  @media (--viewportLarge) {
    height: 370px;
  }
}
