@import '../../styles/propertySets.css';

.root {
  display: none;

  /* Borders */
  outline: none;
  right: 0;
  max-width: 317px;
  /*min-width: 317px;*/

  @media (--viewportSmall) {
    max-width: 417px;
    min-width: 4p17x;
    right: auto;
  }
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);
  width: 245px;
  max-width: 245px;
  height: 183px;
  /* Layout */
  padding: 8px 20px 27px 22px;
  margin-top: 16px;
  background-color: var(--matterColorLight);

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 10px;
  transition: var(--transitionStyleButton);
}

.isOpenAsPopup {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.plain {
  width: 100%;
}

.isOpen {
  display: block;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 245px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0px;
}
.contentWrapperH {
  opacity: 0;
  visibility: hidden;
  height: 0;
}
@media (--viewportMedium) {
  .contentWrapper {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-top: 0px;
  }
}

.label {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  padding: 8px 0 12px 0;
  margin-right: 18px;
}
@media (--viewportMedium) {
  .label {
    padding: 8px 0 16px 0;
  }
}

.inputsWrapper {
  display: flex;
  margin-bottom: 20px;
}

.minPrice {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  height: 50px;
  width: 86px;
  text-align: center;
  border: 1px solid #CACACA;
  border-radius: 5px;
  /*border-bottom-color: var(--attentionColor);*/
  /*border-bottom-width: 3px;*/

  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.maxPrice {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  height: 50px;
  width: 86px;
  text-align: center;
  border: 1px solid #CACACA;
  border-radius: 5px;
  /*border-bottom-color: var(--attentionColor);*/
  /*border-bottom-width: 3px;*/

  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.priceSeparator {
  margin: 8px 8px 0 8px;
}

.priceSliderSpanL, .priceSliderSpanR {
  position: absolute;
  color: #575757;
  top: 50px;
}

.priceSliderSpanR {
  right: 0;
}

@media (--viewportMedium) {
  .priceSeparator {
    margin: 8px 8px 0 8px;
  }
}

.sliderWrapper {
  display: flex;
  padding: 17px 0 25px 0;
  position: relative;

}
@media (--viewportMedium) {
  .sliderWrapper {
    padding: 16px 0 24px 0;
  }
}

.buttonsWrapper {
  display: flex;
}

.clearButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterPlaceholder);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.cancelButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterPlaceholder);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.submitButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterTomato);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
    transition: width var(--transitionStyleButton);
  }
}
