@import '../../styles/propertySets.css';

/* ModalInMobile is visible by default */
.root {
  width: 100%;
  /*display: none;*/
}

/* Content is hidden in Mobile layout */
.modalHidden {
  display: none;
}

.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;
  height: 100%;
/*display: none;*/
@media (--viewportMedium) {
    /*height: 100vh;*/
    flex-basis: 576px;
  }
}

.modalContent {
  width: 100%;

  @media (--viewportMedium) {
    height: 100%;

    /* ModalInMobile content should work with box-shadows etc. */
    overflow: visible;
  }
}
