@import '../../styles/propertySets.css';

.root {
  display: flex;
  position: relative;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.02), 0 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
  justify-content: space-between;
  flex-direction: column;
  background: var(--matterColorLight);
  cursor: pointer;
  margin: 20px;
  overflow: hidden;
  transition: all .2s ease-in-out;
  border-radius: 10px;
  /*border: 1px solid var(--matterColorAnti);*/

  @media (--viewportMedium) {
    border: none;
    margin: 0;
    flex-direction: row;
  }

/* Remove link's hover effect */
  &:hover {
     text-decoration: none;
     transform: scale(1.02);
   }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  border-radius: 10px 10px 0 0;
  position: relative;
  height: 257px;
  transition: var(--transitionStyleButton);
  overflow: hidden;

  @media (--viewportMedium) {
    height: 255px;
    border-radius: 10px 0 0 10px;
    margin-right: 15px;
    width: 25%;
  }

  @media (--viewportLargeWithPaddingsSearch) {
    width: 43%;
    height: 255px;
    max-width: 300px;
  }

}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  /*padding-bottom: 100%; !* 3:2 Aspect Ratio *!*/
  width: 100%;
  height: 100%;
  /*background: var(--matterColorNegative);*/
  /* Loading BG color */
}

.imagePub {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  transition: all .5s ease;
  /*transition-property: background;*/
  /*transition-duration: .2s;*/
  /*transition-timing-function: step-start;*/
  /*animation: fade 5s linear infinite;*/
  /*animation-delay: 2.5s;*/
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  border-radius: var(--borderRadius);

  @media (--viewportMedium) {
    height: 100%;
  }
}

.info {
  display: flex;
  /* Layout */
  user-select: none;
  /* display: flex; */
  /* flex-direction: column; */
  padding: 0 10px;
  /* justify-content: space-between; */

  @media (--viewportMedium) {
    /* padding: 10px 10px 0; */
    /* flex-direction: row; */
    padding: 0;
    width: 72%;
  }
}

.duration {
  width: 45%;
}

.avatarHolder {
  display: flex;
  align-items: center;
  margin-top: 2px;
}

.avatarHolder span {
  font-size: 11px;
}

.avatar {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.mobileTitleBlock {
  display: flex;
  justify-content: space-between;
  margin-right: 11px;
  margin-top: 12px;
  @media (--viewportMedium) {
    margin-top: 17px;
  }
}

.info2 {
  display: none;
  @media (--viewportLargeWithPaddings) {
    display: block;
  }
}

.mobilePrice {
  display: block;
  @media (--viewportLargeWithPaddings) {
    display: none;
  }
}

.headingOfInfo {
  display: block;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 12px;
  color: var(--matterColorLightGray);
  margin-bottom: 3px;
  font-weight: 400;
  @media (--viewportMedium) {
    line-height: 18px;
  }
}
.otherListLi {
  & svg {
  width: 22px;
  height: 22px;
    }
  & p {
  margin-top: 10px;
    }
}

.infoContent {
  color: var(--matterColor);
  font-size: 18px;
  letter-spacing: -0.1px;
  line-height: 26px;
  font-weight: 400;
  /*margin-bottom: -3px;*/
}
.valueKey {
  font-size: 12px;
  font-weight: var(--fontWeightRegular);
  line-height: 30px;
  color: var(--matterTomato);
  margin-left: 10px;
}
.valueKeySkill {
  font-size: 12px;
  font-weight: var(--fontWeightRegular);
  line-height: 30px;
  margin-left: 5px;
  color: var(--matterTomato);
}
.valueKeyFitness {
  font-size: 12px;
  font-weight: var(--fontWeightRegular);
  line-height: 30px;
  color: #EEC255;
}

.infoContentFlex {
  display: flex;
  align-items: flex-end;
  margin-top: 2px;
}

.inforTextUpper {
  text-transform: uppercase;
  margin-left: 6px;
}

.info2 {
  width: 195px;
  position: relative;

  @media (--viewportLargeWithPaddings) {

    padding-left: 0;
    padding-right: 10px;
  }
}

.info2:after {
  @media (--viewportLargeWithPaddings) {
    content: '';
    background-color: #E6E6E6;
    width: 1px;
    position: absolute;
    left: -15px;
    top: 15px;
    bottom: 15px;
  }
}

.buttonHolder {
  margin: 20px -10px 0;

  @media (--viewportLargeWithPaddings) {
    margin: 20px 0 0;
  }
}

.button {
  display: block;
  border-radius: 4px;
  background-color: var(--tomatoColor);
  color: var(--matterColorLight);
  text-align: center;
  padding: 15px;
  left: 0;
  width: 100%;
  bottom: 10px;
  @media (--viewportLargeWithPaddings) {
    position: absolute;
    width: calc(100% - 10px);
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-shrink: 0;
  margin-top: 11px;

  @media (--viewportLargeWithPaddings) {
    margin-right: 18px;
  }
}

.fromValue {
  width: 53%;
}

.level {
  width: 100%;
  margin: 4px 0 12px 0;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  width: 45%;

  @media (--viewportMedium) {
    margin-top: 2px;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;

  @media (--viewportLargeWithPaddings) {
    width: 70%;
  }
}

.bigNumber {
  font-size: 13px !important;
  letter-spacing: -.1px;
}

.fromValueNumber {
  color: var(--tomatoColor);
  font-size: 18px;
  font-weight: var(--fontWeightBold);
  line-height: 21px;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  text-decoration: none !important;
  color: #18171C;
  font-size: 16px;
  line-height: 18px;
  padding-right: 10px;
  letter-spacing: -.3px;
  font-weight: 500;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 18px;
    font-weight: var(--fontWeightBold);
    margin-top: 0;
    padding-right: 0;
    margin-bottom: 0;
    line-height: 24px;
    padding-right: 20px;
  }
}
.ratingStarHolder {
& svg {
  margin-top: -5px;
    width: 16px;
  }
}

.reviews {
  display: flex;
  align-items: center;
  color: var(--matterColorLightGray);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 16px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.reviewsCounter {
  font-weight: var(--fontWeightRegular);
  margin-left: 10px;
  margin-right: 16px;
  line-height: 18px;
}

.hostedBy {
  width: 100%;
}

.editorsChoice {
  background-color: #FF0DBA;
  border-radius: 2rem;
  color: var(--matterColorLight);
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5px;
  padding: 5px 12px 5px 12px;
}

.editorsChoice2 {
  background-color: var(--successColor);
  border-radius: 2rem;
  color: var(--matterColorLight);
  line-height: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5px;
  margin-left: 6px;
  padding: 5px 12px 5px 12px;
}

.descriptionHolder {
  display: none;
  @media (--viewportMedium) {
    color: var(--matterColor);
    font-size: 12px;
    letter-spacing: -0.1px;
    line-height: 18px;
    /*margin-top: 5px;*/
    font-weight: var(--fontWeightRegular);
    padding-right: 26px;
    /* display: block; */

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.amenities {
  display: none;
  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 8px;
  }
}

.amenity {
  width: 50%;
  color: var(--matterColorLightGray);
  font-size: 12px;
  letter-spacing: .21px;
  line-height: 20px;
  align-items: center;
  margin-bottom: 4px;
  font-weight: 400;
  display: flex;
}

.amenityText {
  display: block;
  margin-left: 8px;
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.imageButtonPrev {
  position: absolute;
  width: 30px;
  height: 33px;
  background: rgba(255, 255, 255, .54);
  border-radius: 4px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border: 0;
  outline: none;
}

.imageButtonPrev:after {
  content: '';
  position: absolute;
  border: 2px solid rgba(0, 0, 0, .7);
  border-width: 2px 0 0 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 10px;
  height: 10px;
}

.imageButtonNext {
  position: absolute;
  width: 30px;
  height: 33px;
  background: rgba(255, 255, 255, .54);
  border-radius: 4px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  outline: none;
}

.imageButtonNext:after {
  content: '';
  position: absolute;
  border: 2px solid rgba(0, 0, 0, .7);
  border-width: 2px 2px 0 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 10px;
  height: 10px;
}

.shareLike {
  position: absolute;
  top: 0;
  right: 0;
  /*background: rgba(255, 255, 255, .5);*/
  transition: all .3s;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 40px;
  height: auto;
  padding-top: 10px;
  padding-bottom: 5px;
  z-index: 1;

  @media (--viewportMedium) {
    z-index: 0;
  }
}

.shareLike svg {
  /*width: 27px;*/
  /*height: 24px;*/
  position: relative;
  top: 0;
  right: 12px;
}

.shareButtonsHolder {
  position: absolute;
  left: 100%;
  width: 300px;
}
.checkbox {
  width: 27px;
  height: 24px;
}
.checkedClassNameBorder {
  fill: white;
}
.svgClassName {
  stroke: white;
  fill: none;
}

.shareIcon {
  width: 27px;
  height: 18px;
  position: relative;
  top: 2px;
}

.reviewRating {
  display: flex;
}

.reviewRatingStar {
  width: 15px;
}

.mobileReviewRating {
  margin-right: 5px;
  & svg {
      margin-right: 5px;
      fill: #EC5E69;
    }
}

.amenitiesImageHolder {
  width: 20px;
  height: 22px;
  overflow: hidden;
}


.blockMapRedirect {
  position: absolute;
  cursor: pointer;
  bottom: -18;
  left: 39%;
  font-weight: var(--fontWeightRegular);
  background: var(--matterTomato);
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--matterColorLight);
  height: 33px;
  width: 85px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16), 0 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0 20px 13px rgba(0, 0, 0, 0.01), 0 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
  border-radius: 5px;
  @media (--viewportMedium) {
    display: none;
  }
}
.imgMapRedirect {
  margin-right: 11px;
  height: 18px;
  width: 14px;
  background-image: url("data:image/svg+xml,%3Csvg height='18' viewBox='0 0 14 18' width='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='M6.99995 0.200012C3.24839 0.200012 0.199951 3.24845 0.199951 7.00001C0.199951 9.10626 1.85933 11.8188 3.47495 14.1C5.09058 16.3813 6.69995 18.1875 6.69995 18.1875C6.77651 18.2734 6.88589 18.3234 6.99995 18.3234C7.11401 18.3234 7.22339 18.2734 7.29995 18.1875C7.29995 18.1875 8.90933 16.3906 10.525 14.1125C12.1406 11.8344 13.8 9.1172 13.8 7.00001C13.8 3.24845 10.7515 0.200012 6.99995 0.200012ZM6.99995 1.00001C10.3187 1.00001 13 3.68126 13 7.00001C13 8.66564 11.4593 11.4156 9.87495 13.65C8.52964 15.5469 7.37651 16.8719 6.99995 17.3C6.62339 16.8703 5.47026 15.5375 4.12495 13.6375C2.54058 11.3984 0.999951 8.65157 0.999951 7.00001C0.999951 3.68126 3.6812 1.00001 6.99995 1.00001ZM3.12495 4.17501C2.54526 4.9672 2.19995 5.9422 2.19995 7.00001C2.19995 8.05782 2.54526 9.03282 3.12495 9.82501L3.77495 9.36251C3.28901 8.70001 2.99995 7.88282 2.99995 7.00001C2.99995 6.1172 3.28901 5.30001 3.77495 4.63751L3.12495 4.17501ZM10.875 4.17501L10.225 4.63751C10.7109 5.30001 11 6.1172 11 7.00001C11 7.88282 10.7109 8.70001 10.225 9.36251L10.875 9.82501C11.4546 9.03282 11.8 8.05782 11.8 7.00001C11.8 5.9422 11.4546 4.9672 10.875 4.17501ZM6.99995 5.40001C6.12026 5.40001 5.39995 6.12032 5.39995 7.00001C5.39995 7.8797 6.12026 8.60001 6.99995 8.60001C7.87964 8.60001 8.59995 7.8797 8.59995 7.00001C8.59995 6.12032 7.87964 5.40001 6.99995 5.40001ZM6.99995 6.20001C7.44683 6.20001 7.79995 6.55314 7.79995 7.00001C7.79995 7.44689 7.44683 7.80001 6.99995 7.80001C6.55308 7.80001 6.19995 7.44689 6.19995 7.00001C6.19995 6.55314 6.55308 6.20001 6.99995 6.20001Z'/%3E%3C/svg%3E%0A");
}

.imgContainer {
  width: 100%;
  height: 100%;
}

.listingImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .otherList {
  display: block;
} */
