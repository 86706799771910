@import '../../styles/propertySets.css';

.root {
  position: relative;
  margin: 0 auto;
  width: calc(100% - 20px);
  height: 24px;
}

.rootC {
  position: relative;
  margin: 0 10px;
  width: 100%;
  height: 24px;
  max-width: 316px;


  @media (--viewportMedium) {
    max-width: 382px;
    /*width: calc(49% - 20px);*/
  }
}

.activeHandle {
  z-index: 1;
}

.valueMin, .valueMax {
  font-size: 18px;
  line-height: 30px;
  font-weight: var(--fontWeightRegular);
  position: absolute;
  top: -120%;
  /*top: -120%;*/
  color: var(--matterColorDark);
}
.custom {
  top: -50%;
}
.valueMax {
  right: -10px;
}
.valueMin {
  left: -10px;
}
