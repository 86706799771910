@import '../../styles/propertySets.css';

.heroSearchDateRangePicker {
  margin-bottom: 13px;
  width: 100%;
  height: 80px;
  position: relative;
  z-index: 9;
    & [class*="DateRangePickerInput_calendarIcon"] {
        /*background-image: url("");*/
        margin-top: 9px;
        border-radius: 5px;
      }
    & [class*="DateInput_input"] {
        margin-top: 18px;
      &::placeholder {
         color: var(--matterColor);
       }
    }
    & [class*="FieldDateRangeInput_inputBorders"] {
        display: none;
      }
    & button {
        display: flex;
        margin-left: 21px;
        align-items: center;
        & svg {
            height: 27px;
            width: 28px;
          }
    }
  @media (--viewportLargeWithPaddings) {
    width: 346px;
    margin-bottom: 0;
  }
& :global(.DateRangePicker_picker__directionLeft) {
    top: 87px !important;
  }
& :global(.DateRangePickerInput) {
    border-radius: 5px;
    top: 90px;
  }
& :global(.DateInput) {
    border-radius: 0 5px 5px 0;
  }
}

/*.heroSearchDateRangePicker .DateRangePicker {*/
/*  height: 100%;*/
/*}*/

/*.heroSearchDateRangePicker {*/
/*  @media (--viewportLarge) {*/
/*    width: 42%;*/
/*    margin-bottom: 0;*/
/*  }*/
/*}*/

/*.input {*/
/*  border-bottom : none;*/
/*}*/

.oneFilter {
  width: 100%;

  @media (--viewportLargeWithPaddings) {
    width: 70%;
  }
}
