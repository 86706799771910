@import '../../styles/propertySets.css';
@import '../../styles/customMediaQueries.css';

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.root {
  /*@apply --marketplaceModalRootStyles;*/
  display: flex;
  background: var(--matterColorLight);
  justify-content: center;
  /*@apply --marketplaceModalRootStyles;*/
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportLarge) {
    justify-content: flex-start;
    min-height: calc(100vh - var(--topbarHeightDesktop));
    /*@apply --backgroundImage;*/
  }
}

.content {
  @apply --marketplaceModalBaseStyles;
  padding: 0;
  max-width: 400px;
  margin: 66px 20px;
  border-bottom: none;
  @media (--viewportMedium) {
    margin: 66px 111px;
  }
}

.error {
  @apply --marketplaceModalErrorStyles;
}


/*.singImg {*/
/*  background-image: url("./img/Mask Group.png");*/
/*}*/
/*.verifiImg {*/
/*  background-image: url("./img/verifiImg.png");*/
/*}*/
.confirmImg {
  background-image: url("./img/confirm.png");
}

.authMask {
  display: none;
  width: 647px;
  /*height: 100vh;*/
  /*background-image: url("./img/Mask Group.png");*/
  background-position: center;
  background-size: cover;

@media (--viewportLarge) {
  display: block;
}
}
