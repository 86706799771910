.root {
  display: flex;
  flex-direction: column;
  text-decoration: none !important;
  position: relative;
}
.cartItem {
}

.listingCards {
  /*padding: 0 24px 96px 24px;*/

  & a {
  color: white;
    }
  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
  }

  @media (--viewportLargeWithPaddings) {
    /*padding: 0 36px 96px 5px;*/
  }
}

.listingCard {
  max-width: 820px;
  margin-bottom: 36px;
  flex-basis: 100%;
  text-decoration: none !important;
  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    flex-basis: calc(50% - 12px);
    /*margin-right: 24px;*/
    &:hover {
       text-decoration: none;
     }
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex-basis: calc(33.33% - 16px);
    /*margin-right: 24px;*/
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    /*margin-right: 0;*/
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    /*margin-right: 0;*/
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.iconShare {
  width: 25px;
  height: 18px;
  position: relative;
  top: -6px;
}
.blockMapRedirect {
  position: absolute;
  cursor: pointer;
  bottom: 3px;
  left: 39%;
  font-weight: var(--fontWeightRegular);
  background: var(--matterTomato);
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--matterColorLight);
  height: 33px;
  width: 85px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16), 0 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0 20px 13px rgba(0, 0, 0, 0.01), 0 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
  border-radius: 5px;
  @media (--viewportMedium) {
    display: none;
  }
}
.imgMapRedirect {
  margin-right: 11px;
  height: 18px;
  width: 14px;
  background-image: url("data:image/svg+xml,%3Csvg height='18' viewBox='0 0 14 18' width='14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='M6.99995 0.200012C3.24839 0.200012 0.199951 3.24845 0.199951 7.00001C0.199951 9.10626 1.85933 11.8188 3.47495 14.1C5.09058 16.3813 6.69995 18.1875 6.69995 18.1875C6.77651 18.2734 6.88589 18.3234 6.99995 18.3234C7.11401 18.3234 7.22339 18.2734 7.29995 18.1875C7.29995 18.1875 8.90933 16.3906 10.525 14.1125C12.1406 11.8344 13.8 9.1172 13.8 7.00001C13.8 3.24845 10.7515 0.200012 6.99995 0.200012ZM6.99995 1.00001C10.3187 1.00001 13 3.68126 13 7.00001C13 8.66564 11.4593 11.4156 9.87495 13.65C8.52964 15.5469 7.37651 16.8719 6.99995 17.3C6.62339 16.8703 5.47026 15.5375 4.12495 13.6375C2.54058 11.3984 0.999951 8.65157 0.999951 7.00001C0.999951 3.68126 3.6812 1.00001 6.99995 1.00001ZM3.12495 4.17501C2.54526 4.9672 2.19995 5.9422 2.19995 7.00001C2.19995 8.05782 2.54526 9.03282 3.12495 9.82501L3.77495 9.36251C3.28901 8.70001 2.99995 7.88282 2.99995 7.00001C2.99995 6.1172 3.28901 5.30001 3.77495 4.63751L3.12495 4.17501ZM10.875 4.17501L10.225 4.63751C10.7109 5.30001 11 6.1172 11 7.00001C11 7.88282 10.7109 8.70001 10.225 9.36251L10.875 9.82501C11.4546 9.03282 11.8 8.05782 11.8 7.00001C11.8 5.9422 11.4546 4.9672 10.875 4.17501ZM6.99995 5.40001C6.12026 5.40001 5.39995 6.12032 5.39995 7.00001C5.39995 7.8797 6.12026 8.60001 6.99995 8.60001C7.87964 8.60001 8.59995 7.8797 8.59995 7.00001C8.59995 6.12032 7.87964 5.40001 6.99995 5.40001ZM6.99995 6.20001C7.44683 6.20001 7.79995 6.55314 7.79995 7.00001C7.79995 7.44689 7.44683 7.80001 6.99995 7.80001C6.55308 7.80001 6.19995 7.44689 6.19995 7.00001C6.19995 6.55314 6.55308 6.20001 6.99995 6.20001Z'/%3E%3C/svg%3E%0A");
}
