.favorite {
  display: flex;
  align-items: center;
}

.link {
  color: inherit;
}

.link:hover {
  text-decoration: none;
}

.label {
  font-size: 15px;
  font-weight: var(--fontWeightBold);
  line-height: 20px;
  letter-spacing: 1px;
  color: var(--matterColorDark);
}
