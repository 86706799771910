@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-top: 20px;
  padding-right: 12px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  color: var(--matterColorDark);

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.formWrapper {
  width: 100%;
  z-index: 50;
  top: 100%;
  background: var(--matterColorLight);
}

.formIsClosed {
  visibility: hidden;
  opacity: 0;
}

.formIsOpen {
  opacity: 1;
  visibility: visible;
}

.buttonsWrapper {
  display: flex;
  padding: 20px 0 0;
}

.buttonsMobileWrapper {
  display: flex;
  justify-content: space-between;
}

.clearButton {
  display: none;
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterColorAnti);
  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 14px;

  &:focus,
  &:hover {
     color: var(--matterColor);
     transition: width var(--transitionStyleButton);
   }
}

.cancelButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
     color: var(--matterColor);
     transition: width var(--transitionStyleButton);
   }
}

.submitButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
     color: var(--marketplaceColorDark);
     transition: width var(--transitionStyleButton);
   }
}

.filterLabel {
  @apply --marketplaceH3FontStyles;

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;
  padding: 4px 0 2px 0;
  color: #101010;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.2px;
  text-shadow: none;
  font-family: var(--fontFamily);
}

