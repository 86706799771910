.hostsHolder {

}

.hostsDesktop {
  display: none;
  
  @media (--viewportLarge){
    display: block;
  }
}

.hostsMobile {
  display: block;
  margin-bottom: 0;

  @media (--viewportLarge){
    display: none;
  }
}

.textHolder {
  @media (--viewportLarge){
    width: 70%;
  }
}

.hostName {
  font-size: 16px;
  color: var(--matterColor);
  display: block;
  font-weight: 700;
}

.hostText {
  font-size: 16px;
  color: var(--matterColor);
  display: block;

}

.host {
  display: flex;
  justify-content: space-between;
}

.hostPhoto {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  
  @media (--viewportLarge) {
    width: 138px;
    height: 138px;
  }
}

.accordion {
  width: 100%;
}

.accordionHeading {
  position: relative;
}

.accordionButton {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  outline: none;
  cursor: pointer;
  
  &:active, {
    outline: none;
   }

  &:after {
     display: inline-block;
     content: '';
     height: 10px;
     width: 10px;
     position: absolute;
     margin-right: 12px;
     border-bottom: 1.5px solid currentColor;
     border-right: 1.5px solid currentColor;
     transform: rotate(45deg);
     right: 0;
     top: 16px;
   }
}

.accordionButton[aria-expanded='true']:after {
  transform: rotate(-136deg);
}

.dataAccHolder {
  margin-left: 10px;
}
