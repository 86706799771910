@import '../../styles/propertySets.css';

.heading {
  margin: 18px 0 18px 0;

@media (--viewportMedium) {
  margin:

24px

0 24px

0;
}

}

.wrapperSideNav {

@media (--viewportLargeWithPaddingsSearch) {
  padding:

60px

25px

82px

calc((100% - 1310px

) / 2);
  background:

var(--matterColorLight

);
& nav {
    padding: 30px 40px 38px 30px;
    margin-top: 0;
    background: #FAFAFA;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
    border-radius: 10px;
  }
}

}

.wrapperContent {

@media (--viewportLarge) {
  padding-top:

60px

;
  padding-left:

40px

;
  border: none

;
}

}

.content {

@media (--viewportLarge) {
  max-width:

925px

;
  width:

925px

;
}

&
h1 {
  font-size: 17px;
  font-weight: bold;
  line-height: 21px;
  margin: 21px 0 0 0;
}

&
p {
  margin: 0;
  font-size: 13px;
  line-height: 19px;
  font-weight: normal;
  padding-top: 11px;
}

&
li {
  margin: 0;
  font-size: 13px;
  line-height: 19px;
  font-weight: normal;
  padding-top: 11px;
}

}

.bottomText {
  margin-top: 30px;
}

.bottomTitle {
  margin-top: 50px;
}

.bottomTitle p {
  font-size: 17px;
  font-weight: bold;
}

.addressHolder {
  margin-bottom: 20px;
}

.addressHolder p {
  margin: 0px;
  padding-top: 0px;
}

.dataHolder {
  display: flex;
  flex-wrap: wrap;
  /*border-bottom: 0px solid var(--matterColorNegative);*/
}

.dataHolder p {
  margin: 0px;
}

.legalHolder {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}

.dataLabel {
  flex: 0 0 35%;
  max-width: 35%;
  margin-right: 10px;
}

.dataText {
  flex: 0 0 calc(65% - 10px);
  max-width: calc(65% - 10px);
}



