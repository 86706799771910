@import '../../styles/propertySets.css';

.menuContent {
  margin-top: 7px;
  /* padding-top: 13px; */
  padding: 13px 0;
  min-width: 300px !important;
  border-radius: 4px;
  right: 0;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0;
  height: calc(100% - 4px);
  width: 0;
  /*background-color: var(--marketplaceColor);*/
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--matterColorDark);
}

.menuItem svg {
  max-width: 25px;
  max-height: 25px;
  margin-right: 15px;
}

.menuItem:hover svg {
  fill: var(--marketplaceColor);
}

.menuItem {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus {
     color: var(--matterColorDark);
   }

  &:hover {
     color: var(--marketplaceColor);
   }

  &:hover .menuItemBorder {
     width: 6px;
   }
}

.languageCurrencyMenu {
  margin-right: 20px;
  height: auto;
  min-width: 30px;
}

.selectedLang {
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 20px;
  text-transform: capitalize;
}

.languageCurrencyMenuMobile {
  width: 40px;
  margin-left: 28px;

  @media (--viewportMedium) {
    margin-left: 0;
  }
}

.languageCurrency {
  display: flex;
  align-items: center;
}

.langMenuHolder {
  display: flex;
}

.menuLabel {
  position: relative;
  padding: 15px 0 0 0 ;
  text-align: left;
}

.menuLabel:focus {
  outline: none;
}

.menuLabel:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid var(--matterColor);
  border-width: 0 0 2px 2px;
  top: 50%;
  right: -11px;
  transform: rotate(-45deg) translateY(-45%);
}
