@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.noDateMessage {
  display: block;
  pointer-events: none;
  position: absolute;
  z-index: 50;
  min-width: 260px;
  margin-top: 7px;
  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  transition: ease-in-out 0.1s;
  padding: 20px;
  right: 0;

  @media (--viewportSmall) {
    min-width: 300px;
    right: auto;
  }
}


.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: 4px 18px;
  width: auto;
  height: 40px;
  min-height: 0;
  border-radius: 5px;

  width: 100%;

  @media (--viewportSmall) {
    width: auto;
  }

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: 4px 18px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 5px;
  background-color: #296151;
  border: 1px solid #296151;

  width: 100%;

  @media (--viewportSmall) {
    width: auto;
  }

  &:hover,
  &:focus {
     border: 1px solid #239954;
     background-color: #239954;
  }
}
