@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: 9px 16px 10px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 0;
  border: none;

  /*@media (--viewportMedium){*/
  /*  margin: 0 0 0 -10px;*/
  /*}*/
}

.list {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.item {
  padding: 0;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (--viewportMedium){
    margin-bottom: 0;
  }
}

.icon {
  width: 23px;
  height: 23px;
  margin-right: 8px;
}

.iconSmall {
  margin-right: 8px;
  height: 23px;
}

.text {
  padding-bottom: 5px;
  line-height: 26px;
  font-weight: var(--fontWeightRegular);
  font-size: 18px;
}

.label {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.checkWrapper {
  display: none;
}

.root {
  display: flex;
  flex-wrap: wrap;
}

.twoColumns {
@media (--viewportMedium) {
  column-count: 2;
}
}

.hidden {
  visibility: hidden;
}

.marketplaceFill {
  fill: var(--marketplaceColor);
}

.iconWrapper {
}

.labelWrapper {
  display: inline-block;
  margin-top: 7px;
}

.selectedLabel,
.notSelectedLabel {
  @apply --marketplaceBodyFontStyles;
  line-height: var(--PropertyGroup_lineHeight);
  margin: 0;
}

.selectedLabel {
  font-weight: var(--fontWeightSemiBold);
}

.notSelectedLabel {
  position: relative;
  width: 65px;
  height: 70px;
}

.selectedIcon {
  fill: var(--marketplaceColor);
  width: 65px;
  height: 70px;
}

.notSelectedIcon {
  width: 65px;
}

.iconRoot {
  fill: var(--marketplaceColor);
}

.st0 {
  fill: none;
  stroke: --matterColorDark;
  stroke-width: 10;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.selectedIcon .st0 {
  stroke: var(--marketplaceColor);
}

.st1 {
  fill: none;
  stroke: --matterColorDark;
  stroke-width: 10;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.selectedIcon .st1 {
  stroke: var(--marketplaceColor);
}

.st2 {
  fill: none;
  stroke: #1D1D1B;
  stroke-width: 10;
  stroke-linecap: round;
  stroke-miterlimit: 10;
}

.selectedIcon .st2 {
  stroke: var(--marketplaceColor);
}

.st3 {
  fill: none;
  stroke: #1D1D1B;
  stroke-width: 10;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.selectedIcon .st3 {
  stroke: var(--marketplaceColor);
}


.selectedIcon .st5 {
  fill: var(--marketplaceColor);
}

