@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: 4px 18px;
  width: auto;
  height: auto;
  max-height: 40px;
  min-height: 0;
  border-radius: 5px;
  display: flex;
  flex-wrap: nowrap;

  width: 100%;

  @media (--viewportSmall) {
    width: auto;
  }

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;

  display: flex;
  flex-wrap: nowrap;
  padding: 4px 18px;

  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 5px;
  background-color: #296151;
  border: 1px solid #296151;

  width: 100%;

  @media (--viewportSmall) {
    width: auto;
  }

  &:hover,
  &:focus {
     border: 1px solid #239954;
     background-color: #239954;
  }
}
.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 420px;
  margin-top: 16px;
  background-color: var(--matterColorLight);

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.16), 0 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0 20px 13px rgba(0, 0, 0, 0.01), 0 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
  border-radius: 10px;
  transition: var(--transitionStyleButton);
}

.popupSize {
  padding: 20px 20px 18px 22px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
