@import '../../styles/propertySets.css';

.root {
  max-height: calc(100vh - 150px);
  padding-right: 20px;
  overflow: auto;
  scrollbar-color: rgba(41, 97, 81, 0.2) white;

  & hr {
      opacity: 0;
      padding: 0;
      margin: 0;
    }
  & div {
      border: none !important;
      padding: 0 !important;
    }
  & a {
      color: var(--matterColorDark) !important;
    }
  & ul {
      padding: 0 !important;
    }
  & li {
      font-size: 18px !important;
      line-height: 26px !important;
      letter-spacing: 0em !important;
      color: var(--matterColorDark);
      font-weight: var(--fontWeightRegular) !important;
    }
  & p {
      font-size: 18px !important;
      line-height: 26px !important;
      letter-spacing: 0em !important;
      padding: 0 !important;
      color: var(--matterColorDark);
      font-weight: var(--fontWeightRegular) !important;
  & strong {
      font-weight: var(--fontWeightRegular) !important;
    }
  }
  & b {
      font-size: 18px !important;
      line-height: 26px !important;
      letter-spacing: 0em !important;
      padding: 0 !important;
      color: var(--matterColorDark);
      font-weight: var(--fontWeightRegular) !important;
    }
  & h2 {
      margin: 30px 0 !important;
      padding: 0 !important;
      font-size: 30px !important;
      line-height: 35px !important;
      font-weight: var(--fontWeightBold) !important;
      color: var(--matterColorDark);
      letter-spacing: -0.03em !important;
    }
  & h3 {
      padding: 0 !important;
      font-size: 20px !important;
      margin: 30px 0 !important;
      line-height: 26px !important;
      color: var(--matterColorDark);
      font-weight: var(--fontWeightBold) !important;
      letter-spacing: -0.02em !important;
    }

  & h4 {
        padding: 0 !important;
        font-size: 20px !important;
        margin-top: 30px !important;
        line-height: 26px !important;
        color: var(--matterColorDark);
        font-weight: var(--fontWeightBold) !important;
        letter-spacing: -0.02em !important;
      }
  & h5 {
      padding: 0 !important;
      font-size: 20px !important;
      margin-top: 30px !important;
      line-height: 26px !important;
      color: var(--matterColorDark);
      font-weight: var(--fontWeightBold) !important;
      letter-spacing: -0.02em !important;
    }

  @media (--viewportMedium) {
    max-height: 680px;
  }

/* scrollBar in modal */

  &::-webkit-scrollbar {
       width: 6px;
     }
  &::-webkit-scrollbar-track {
     background: rgba(202, 202, 202, 0.3);
   }
  &::-webkit-scrollbar-thumb {
     background: rgba(41, 97, 81, 0.2);
   }

  & p {
    @apply --marketplaceH4FontStyles;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.04em;
      color: var(--matterColorDark);
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 30px;

    @media (--viewportMedium) {
      margin-top: 0;
      /*margin-bottom: 30px;*/
    }
  }
}
