@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.pageTitle {
  text-align: left;
  margin-bottom: 20px;
  margin-top: 10px;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1274px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 60px auto;
  }
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.photoLink {
  margin-top: -20px;
}


.contentWrapper {
  display: flex;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.contentSide {
  /*border-right: 1px solid var(--matterColorNegative);*/
  @media (--viewportMedium) {
    width: 285px;
    min-width: 285px;
    padding: 30px 20px 26px 30px;
    margin-right: 64px;
    margin-top: 0;
    height: 100%;
    background: #FAFAFA;
    min-height: 200px;
    position: sticky;
    top: 120px;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
    border-radius: 10px;
  }
}

.contentMain {
  width: 100%;
  font-weight: var(--fontWeightBold);
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    max-width: 925px;
    width: 925px;
    width: 90%;
  }

  & a {
      color: var(--matterColorDark);
    }
  & p {
      font-size: 20px;
      line-height: 34px;
      letter-spacing: 0em;
      margin: 30px 0;
      color: var(--matterColorDark);
      font-weight: var(--fontWeightRegular);
    }
  & > h2 {
      color: var(--matterColorDark);
      margin-top: 0 !important;
    }
  & h2 {
      margin: 30px 0;
      font-size: 30px;
      line-height: 35px;
      color: var(--matterColorDark);
      font-weight: var(--fontWeightBold);
      letter-spacing: -0.03em;
    }
  & h3 {
      font-size: 20px;
      line-height: 26px;
      color: var(--matterColorDark);
      font-weight: var(--fontWeightBold);
      letter-spacing: -0.02em;
    }
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  /*margin-bottom: 3px;*/
  margin-top: 0;
  display: block;
  font-size: 18px;
  line-height: 22px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: -0.04em;
  color: var(--matterColor);
  /*color: var(--matterColorLightGray);*/
}

.founder {
  margin-top: 40px;
  margin-bottom: 0;
  line-height: 25px;
}

.founderImage {
  width: 100%;
}

.navList {
  display: flex;
  flex-direction: column;
  margin-top: 3px;
}

.navItem {
  list-style: none;
}

.list {
  padding-left: 20px;
  font-size: 20px;
  line-height: 34px;
  list-style-type: disc;
  margin-bottom: 30px;
}

.list li {
  font-size: 20px;
  line-height: 34px;
  margin-bottom: 7px;
}

.titlePageText {
  font-size: 30px;
  line-height: 35px;
  letter-spacing: -0.03em;
}
.imgBlock {
  height: 250px;
  margin-bottom: 30px;
  @media (--viewportLarge) {
    height: 370px;
  }
}
