@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
  color: var(--matterColorDark);
  letter-spacing: -0.2px;
  font-weight: var(--fontWeightBold);
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  @media (--viewportMedium) {
    font-size: 36px;
    line-height: 42px;
  }
}

.blockQuote {
  font-style: italic;
  font-size: 18px;
  width: auto;
  /*padding-left: 10px;*/
  line-height: 30px;
  text-align: center;
  margin: 0 20px 30px 20px;
  & .quoteText {
      font-size: 17px;
      line-height: 30px;
      font-weight: var(--fontWeightRegular);
    }
  & .quoteName {
      color: var(--matterTomato);
      font-weight: var(--fontWeightBold);
    }
  & .quoteNameAuthor {
      font-weight: var(--fontWeightBold);
    }
  @media (--viewportMedium) {
    margin: 0 auto 98px;
    padding-left: 0;
    width: 712px;
  }
}

.sectionBlockItem {
  margin: 0 21px;
  display: flex;
  flex-direction: column;
  @media (--viewportLarge) {
    margin: 0 10px 100px;
    flex-direction: row;
  }
}

.textBlockItem {
  position: relative;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  margin-left: 23px;
  &:nth-child(1) {
     margin-right: 20px;
     margin-left: 66px;
     text-align: right;
    & .imgItemText {
        top: -30px;
        right: -10px;
      }
   }
  & .titleItem {
      font-size: 30px;
      line-height: 35px;
      color: #18171C;
      font-weight: var(--fontWeightBold);
      margin: 40px 0 10px;
    }
  & .textItem {
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.1px;
      color: #18171C;
      font-weight: var(--fontWeightRegular);
      margin: 0;
    }
  & .imgItemText {
      top: -30px;
      left: -10px;
      font-size: 180px;
      line-height: 240px;
      font-weight: var(--fontWeightBold);
      opacity: 0.05;
      position: absolute;
    }
    @media (--viewportLarge) {
      display: flex;
    }
}

.imgItem {
  margin: 0 auto;
  border-radius: 10px !important;
  object-fit: cover;
  height: 189px;
  max-height: 350px;
  width: 100%;

  @media (--viewportMedium) {
    width: 600px;
    height: 350px;
  }
}
.textBlockItemSmall {
  position: relative;
  display: flex;
  height: 210px;
  align-items: center;
  justify-content: center;
  @media (--viewportLarge) {
    display: none;
  }
  & .imgItemTextSmall {
      top: -30px;
      left: -10px;
      position: absolute;
      font-size: 180px;
      line-height: 240px;
      font-weight: var(--fontWeightBold);
      opacity: 0.1;
      @media (--viewportSmall) {
        left: 200px;
      }
    }
    & .textItemSmall {
        margin-top: 0;
        margin-left: 20px;
        width: 293px;
        color: #18171C;
        font-weight: var(--fontWeightBold);
        font-size: 20px;
        line-height: 26px;
      }
}


.understandButton {
  @apply --marketplaceButtonStyles;
  width: 252px;
  padding: 0;
  height: 47px;
  min-height: 47px;
  border-radius: 5px;
}
