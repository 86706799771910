@import '../../styles/propertySets.css';
.root {
  display: none;
  @media (--viewportMedium) {
    /*padding: 0 28px 0 5px;*/
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    background-color: var(--matterColorBright);
    /*padding: 24px 24px 0;*/
    flex-wrap: wrap;
  }
}

.longInfo {
  flex-wrap: wrap;
  max-width: 820px;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /*height: 40px;*/
  justify-content: space-between;

  & > div {
    /*margin-bottom: 10px;*/
  }

  & > *:not(:last-child) {
    /*margin-right: 8px;*/
      height: 40px;
    margin-bottom: 10px;
  }

  & > div.resetAllButtonHolder {
      /*margin-bottom: 10px;*/
  }

  @media (--viewportLargeWithPaddings) {
    & > div {
      margin-bottom: 0;
    }
  }

  & > div,
  & > button {
    width: calc(50% - 16px);

    @media (--viewportSmall) {
      width: auto;
    }
  }
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}
.titleSearchResult {
  display: flex;
  height: 35px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  @media (--viewportMedium) {
    margin-bottom: 20px
  }
}
.titleCategoryActive {
  line-height: 30px;
  font-size: 35px;
  color: #101010;
  font-weight: var(--fontWeightBold);
}

.searchResultSummary {
  @apply --marketplaceH4FontStyles;
  line-height: 16px;
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  color: #9E9E9E;
  text-transform: uppercase;
  /*margin-top: 10px;*/
  /*margin-bottom: 11px;*/
  /*margin-left: 0px;*/
  /*margin-right: 8px;*/
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.noSearchResults {
  @apply --marketplaceH4FontStyles;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
}

.loadingResults {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: var(--matterColorBright);
}

.resultsFound {
  white-space: nowrap;
}

.searchFiltersPanelClosed {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  display: inline-block;
  padding: 5px 18px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 5px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  white-space: nowrap;
  display: inline-block;
  padding: 5px 18px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 5px;
  background-color: #296151;
  border: 1px solid #296151;

  &:hover,
  &:focus {
     border: 1px solid #239954;
     background-color: #239954;
  }
}

.resetAllButtonHolder {
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: center;

  @media (--viewportSmall) {
    justify-content: flex-start;
  }

  & svg {
    width: 7px;
    height: 6px;
    margin-right: 3px;
    stroke: var(--tomatoColor);
    fill: var(--tomatoColor);
  }
}

.resetAllButton {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--tomatoColor);
  font-size: 12px;
  line-height: 18px;

  /* Layout */
  margin: 0;

  @media (--viewportSmall) {
    margin: 0 auto 0 0;
  }

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
     color: var(--tomatoColor);
   }
}

.bookingDateRangeFilter {

  & > div {
    margin-top: 57px;
  }
}

.classCustomBlock {
  /*padding: 0 36px 25px 36px;*/

  & > button:last-child {
      padding: 14px 20px;
      background: var(--matterTomato);
      color: var(--matterColorLight);
      border-radius: 5px;
   }
}
