.root {
  position: relative;
}
.root label {
  color: var(--matterColor);
}
.input {
  border-bottom-color: var(--attentionColor);
}

.inputSuccess {
  position: relative;
  border-bottom-color: var(--successColor);
}
.inputSuccess + span {
  content: '';
  position: absolute;
  width: 15px;
  height: 8px;
  border: 3px solid  var(--successColor);
  top: 55%;
  right: 15px;
  border-width: 0 0 3px 3px;
  transform: rotate(-45deg);

  @media screen and (min-width: 768px){
    & {
      top: 55%;
      right: 15px;
    }
  }
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}
