@import '../../styles/propertySets.css';

.root {
  /* min-width: 820px; */
}

.heading {
  font-weight: var(--fontWeightBold);
  font-size: 30px;
  line-height: 38px;
  color: #101010;
  margin: 0 0 20px;
}

.fieldsHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  
  @media (--viewportSmall) {
    margin-bottom: 20px;
  }

  & .field {
    width: 100%;
    
    @media (--viewportSmall) {
      width: calc(50% - 15px);
      margin-bottom: 0;
    }
  }
}

div.field {
  margin-bottom: 20px;

  & label {
    font-size: 14px;
    line-height: 22px;
    font-size: var(--fontWeightBold);
    margin: 0;
    padding: 0 0 4px;
    color: var(--customBlack);
  }

  & input {
    padding: 11px 40px 11px 15px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;

    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: var(--customBlack);

    transition: all 0.3s;

    &:hover,
    &:focus {
      border: 1px solid var(--matterColor);
    }

    &::placeholder {
      color: #929292;
    }
  }

  & [class*="inputSuccess"] + span {
    top: 45px;
  }
}

.fieldHidden {

  & [class*="inputSuccess"] + span {
    display: none;
  }
}

.optionsTitle {
  font-size: 20px;
  line-height: 26px;
  font-weight: var(--fontWeightBold);
  margin: 0 0 10px;
  color: var(--customBlack);
}

.optionsSubTitle {
  font-size: 12px;
  line-height: 18px;
  font-weight: var(--fontWeightRegular);
  margin: 0 0 20px;
}

.optionsSectionTitle {
  padding: 30px 20px;
  color: var(--matterColorLight);
  border-radius: 10px;
  margin: 0 0 20px;
}

.optionsSectionTitleSnow {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../assets/snow-bg.jpg') no-repeat center;
  background-size: cover;
  color: var(--customBlack);
}

.optionsSectionTitleWater {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../assets/water-bg.jpg') no-repeat center;
  background-size: cover;
}

.optionsSectionTitleBike {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../../assets/bike-bg.jpg') no-repeat center;
  background-size: cover;
  color: var(--customBlack);
}

.optionsSectionTitleFoot {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../../assets/foot-bg.jpg') no-repeat center;
  background-size: cover;
  color: var(--customBlack);
}

.optionsSectionTitleOther {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../assets/other-bg.jpg') no-repeat center;
  background-size: cover;
}

.optionsHolder {
  
  @media (--viewportSmall) {
    display: flex;
    flex-wrap: wrap;
  }
}

.textInputRowBike {
  & input:checked + label svg path {
    fill: var(--matterColorLight);
    stroke: none !important;
  }
}

.bottomDescription {
  margin: 10px 0 0;
}

.bottomDescriptionItem {
  margin: 0;
  color: #101010;
  font-size: 18px;
  line-height: 26px;
}

.buttonHolder {
  max-width: 180px;
  margin: 30px auto;

  & button {
    width: 100%;
    min-height: 54px;
  }
}

.getInTouch {
  display: block;
  position: relative;
  margin: 20px 0;

  & input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 30px;
    cursor: pointer;
  }

  & label span {
    color: #101010;
    font-size: 18px;
    line-height: 26px;
  }
}
