@import '../../styles/propertySets.css';

.staticPageWrapper {
  width: 100%;
}

.rootTopSection {
  display: flex;
  flex-direction: row;
  margin: 0 10px 30px;
  @media (--viewportMedium) {
    width: 430px;
    margin: 0 0 60px;
    height: auto;
  }
}

.containerTopSection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.titleTopSection {
  color: #000000;
  text-transform: uppercase;
  font-weight: var(--fontWeightBold);
  font-size: 16px;
  line-height: 24px;
  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.topTitleSection {
  text-align: center;
  color: #000000;
  font-weight: var(--fontWeightBold);
  font-size: 20px;
  margin: 0 10px 30px;
  line-height: 35px;
  @media (--viewportMedium) {
    font-size: 30px;
    width: 820px;
    margin: 0 0 100px;
  }
}

.titleBlock {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.imgTopSection {
  margin-top: 45px;
  margin-right: 15px;
  width: 40px;
  height: 40px;
  @media (--viewportMedium) {
    width: 60px;
    height: 60px;
    margin-right: 30px;
  }
}

.contentTopSection {
  width: 276px;
  color: #000000;
  font-weight: var(--fontWeightRegular);
  font-size: 14px;
  line-height: 26px;
  letter-spacing: -0.04em;
  @media (--viewportMedium) {
    width: 296px;
    font-size: 18px;
  }
}


.rootSectionSendEmail {
  margin-bottom: 30px;
  @media (--viewportMediumSmall) {
    margin-bottom: 100px;
  }
}
.sectionDiscount {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  & .sectionMount {
      width: 100%;
    @media (--viewportMediumSmall) {
      width: 84%;
    }
  }
  & .sectionMap {
      width: 100%;
    @media (--viewportMediumSmall) {
      width: 51%;
    }
  }
  @media (--viewportMediumSmall) {
    height: 439px;
    flex-direction: row;
  }
}

.sectionMount {
  position: relative;
  background-image: url("./img/Mask Group.png");
  background-size: contain;
  object-fit: contain;
  display: flex;
  overflow: hidden;
  height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (--viewportSmall) {
    height: 439px;
  }
  @media (--viewportMedium) {
    background-size: cover;
    object-fit: cover;
  }
}
.mountMobile {
  position: absolute;
  display: block;
  @media (--viewportMedium) {
    display: none;
  }
}
.mountDesktop {
  position: absolute;
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}
.imgMap {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: .4;
  background-image: url("./img/newMap.png");
}
.sectionMap {
  position: relative;
  width: auto;
  margin: 0;
  background-size: cover;
  display: flex;
  height: 226px;
  flex-direction: column;
  background-position: 1%;
  max-width: 100%;
  & .textInMap {
      margin-top: 36px;
      display: flex;
      text-align: center;
      flex-direction: column;
    @media (--viewportSmall) {
      margin-top: 96px;
    }
  }
  & .logoMap {
      height:56px;
  }

  @media (--viewportSmall) {
    height: 439px;
    margin: 0;
    align-items: center;
  }
  @media (--viewportLarge) {
    height: auto;
  }
}
.ImgMapItem {
  position: absolute;
}

.titleMapSection {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.04em;
}

.subTitleMapSection {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -1px;
  @media (--viewportSmall) {
    font-size: 36px;
    line-height: 42px;
  }
}


.rootLinks {
  margin: 0 auto 40px;
  max-width: 800px;
  @media (--viewportLargeWithPaddingsSearch) {
    margin: 0 auto 123px;
    max-width: 1250px;
  }
}

.titleLinksSection {
  display: flex;
  flex-direction: column;
  max-width: 657px;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -1px;
  color: #000000;
  margin-left: 15px;
  margin-bottom: 14px;
  @media (--viewportMediumSmall) {
    margin-bottom: 24px;
    font-size: 26px;
    line-height: 42px;
  }
  @media (--viewportLargeWithPaddingsSearch) {
    margin-left: 0;
    margin-bottom: 44px;
    font-size: 36px;
    line-height: 42px;
  }
}

.wrapperLinks {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  max-width: 800px;
  @media (--viewportLargeWithPaddingsSearch) {
    margin: 0;
    max-width: 1250px;
  }
}

.link {
  height: 100px;
  width: 295px;
  display: flex;
  margin: 0 5px;
  justify-content: center;
  align-items: center;
  background: #CACACA;
  @media (--viewportMediumSmall) {
    height: 150px;
  }
  @media (--viewportLargeWithPaddingsSearch) {
    height: 295px;
  }
  &:hover {
     text-decoration: none;
   }
}

.linkText {
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  @media (--viewportMediumSmall) {
    font-size: 18px;
  }
}

.rootRecommendations {
    margin: 0 auto;
  @media (--viewportMediumSmall) {
    max-width: 1240px;
  }
}

.titleRecommendations {
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  line-height: 42px;
  letter-spacing: -1px;
  margin: 0 0 30px 15px;
  color: #000000;
  @media (--viewportLarge) {
    text-align: left;
    margin: 0 0 60px 5px;
  }
}

.sectionBlockItem {
  margin: 0 21px;
  display: flex;
  flex-direction: column;
  @media (--viewportLarge) {
    margin: 0 10px 100px;
    flex-direction: row;
  }
}

.textBlockItem {
  position: relative;
  display: none;
  flex-direction: column;
  /*align-items: center;*/
  justify-content: center;
  margin-bottom: 50px;
  margin-left: 23px;
  & .titleItem {
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
      color: #18171C;
      margin: 40px 0 20px;
    }
  & .textItem {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.04em;
      color: #18171C;
      margin: 0;
    }
  & .textItemRight {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      padding-right: 20px;
      letter-spacing: -0.04em;
      color: #18171C;
      margin: 0;
    }
  & .imgItemText {
      top: -30px;
      left: -10px;
      font-size: 180px;
      line-height: 240px;
      font-weight: var(--fontWeightBold);
      opacity: 0.05;
      position: absolute;
    }
  @media (--viewportLarge) {
    display: flex;
  }
}

.imgItem {
  margin: 0 auto;
  border-radius: 10px !important;
  object-fit: cover;
  height: 189px;
  max-height: 350px;
  width: 100%;

  @media (--viewportMedium) {
    width: 610px;
    height: 350px;
  }
}
.textBlockItemSmall {
  position: relative;
  display: flex;
  font-size: 20px;
  line-height: 26px;
  color: #18171C;
  font-weight: var(--fontWeightBold);
  align-items: center;
  margin: 10px 0;
  justify-content: center;
  @media (--viewportLarge) {
    display: none;
  }
}


.understandButton {
  @apply --marketplaceButtonStyles;
  width: 252px;
  padding: 0;
  height: 47px;
  min-height: 47px;
  border-radius: 5px;
}
