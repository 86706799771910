@import '../../styles/propertySets.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .box,
  &:focus + label .box,
  &:checked + label .box {
    stroke: var(--marketplaceColor);
  }

/*&:hover + label .boxDecor,*/
/*&:focus + label .boxDecor,*/
/*&:checked + label .boxDecor {*/
/*   display: block;*/
/* }*/

/*&:checked + label .checkedDecor {*/
/*   display: block;*/
/* }*/


  /* successColor version */
  &:hover + label .boxSuccess,
  &:focus + label .boxSuccess,
  &:checked + label .boxSuccess {
    stroke: var(--successColor);
  }

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: inline;
    stroke: var(--marketplaceColor);
    stroke-width: 1px;
  }
  &:checked + label .checkedDecor {
   display: block;
 }
  &:checked + label .boxDecor {
   display: none;
 }

  /* Display the "check" when checked */
  &:checked + label .checkedSuccess {
    display: inline;
    stroke: var(--successColor);
    stroke-width: 1px;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--matterColor);
  }
}

.rootWithIcon {
  margin: 0 20px 20px 0;

  & input {
    left: -24px;
    top: 0;
    opacity: 0;
    width: calc(100vw - 36px);
    height: 100%;
    z-index: 1;
    
    @media (--viewportMedium) {
      left: 0;
      width: 100%;
    }
  }

  & input:checked + label {
    background: #296151;
    border: 1px solid #296151;
    
    & .textRoot {
      color: var(--matterColorLight);
    }

    & svg path {
      stroke: var(--matterColorLight);
    }
  }

  & label {
    display: flex;
    width: auto;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #929292;
    padding: 5px 15px 5px 5px;
  }

  & .textRoot {
    font-size: 14px;
    line-height: 22px;
    font-weight: var(--fontWeightBold);
  }

  & .checkboxWrapper {
    margin: 0;
    height: 40px;
  }
}




.boxDecor {
  display: block;
}
.checkedDecor {
  display: none;
}

.label {
  display: flex;
  align-items: center;
  padding: 0;
}

.checkboxWrapper {
  /* This should follow line-height */
  height: 32px;
  margin-top: -2px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checked {
  display: none;
  fill: var(--marketplaceColor);
}
.checkedSuccess {
  display: none;
  fill: var(--successColor);
}

.boxSuccess,
.box {
  stroke: var(--matterColorAnti);
}

.text {
}
.textRoot {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}
