.activityHolder {
  display: flex;
  flex-wrap: wrap;
  min-height: 200px;
}

.activityName {
  color: var(--matterColorLightGray);
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 0;
}

.activityDescription {
  margin-top: 0;
  font-size: 17px;
  line-height: 23px;
  font-weight: 400;
}

.accordionHolder {
  padding-top: 10px;
  flex: 0 0 100%;
  max-width: 100%;

  @media (--viewportMedium) {
    flex: 0 0 40%;
    width: 40%;
    padding-left: 16px;
  }
}

.galleryHolder {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 20px;

  @media (--viewportLarge) {
    flex: 0 0 55%;
    max-width: 55%;
    margin-top: 40px;
    padding-left: 10px;
    border-left: 2px solid #f3f3f3;
  }
}

.infoHolder {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 20px;

  @media (--viewportLarge) {
    flex: 0 0 45%;
    max-width: 45%;
  }
}

.gallerySlider {
  max-height: 500px;
  overflow: hidden;
  padding-bottom: 70px;
  font-size: 18px;
}

.slickPrev {
  left: 0;
  display: block;
  width: 40px;
  position: absolute;
  top: calc(50% - 50px);
  left: 0;
  background: rgba(255, 255, 255, 0.4);
  height: 40px;
  z-index: 10000;

  &:after {
     content: '';
     width: 20px;
     height: 20px;
     border: 3px solid var(--matterColor);
     border-width: 4px 0 0 4px;
     position: absolute;
     top: 50%;
     left: calc(50% + 5px);
     transform: translate(-50%, -50%) rotate(-45deg);
   }
}

.slickNext {
  right: 0;
  display: block;
  width: 40px;
  position: absolute;
  top: calc(50% - 50px);
  background: rgba(255, 255, 255, 0.4);
  height: 40px;
  z-index: 10000;

  &:after {
     content: '';
     width: 20px;
     height: 20px;
     border: 3px solid var(--matterColor);
     border-width: 4px 4px 0 0;
     position: absolute;
     top: 50%;
     left: calc(50% - 5px);
     transform: translate(-50%, -50%) rotate(45deg);
   }
}

.galleryDots {
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  list-style: none;
  text-align: center;
  position: absolute;
  display: flex !important;
}

.thumbLink {
  display: block;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 50px;
  height: 50px;
  margin-right: 5px;
}

.thumbImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
}

.arrow {
  background: red;
  right: 0;
}

.noResult {
  padding: 0 20px;
  font-size: 16px;
  font-weight: 400;
}
.link {
  color: var(--tomatoColor);
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  letter-spacing: -0.0888889px;
  line-height: 19px;
  display: block;
  margin-bottom: 10px;
}

.description {
  & p {
    margin-bottom: 15px !important;
  }
}
