@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    /*justify-content: space-between;*/
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin: 0;

  /*@media (--viewportMedium) {*/
  /*  margin-top: 32px;*/
  /*}*/
}

.inputForm {
  margin-bottom: 20px;
& input {
    font-weight: var(--fontWeightRegular);
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.04em;
    padding: 14px 15px;
    border-radius: 5px;
    border: 1px solid var(--matterPlaceholder);
    &::placeholder {
       color: var(--matterPlaceholder);
     }
  }
}

.firstNameRoot {
  width: calc(50% - 10px);
  & input {
      font-weight: var(--fontWeightRegular);
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.04em;
      padding: 14px 15px;
      border-radius: 5px;
      border: 1px solid var(--matterPlaceholder);
      &::placeholder {
         color: var(--matterPlaceholder);
       }
    }
}

.lastNameRoot {
  width: calc(50% - 10px);
  & input {
      font-weight: var(--fontWeightRegular);
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.04em;
      padding: 14px 15px;
      border-radius: 5px;
      border: 1px solid var(--matterPlaceholder);
    &::placeholder {
       color: var(--matterPlaceholder);
     }
    }
}

.password {
  @apply --marketplaceModalPasswordMargins;
  margin-top: 20px;

  & input {
      font-weight: var(--fontWeightRegular);
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.04em;
      padding: 14px 15px;
      border-radius: 5px;
      border: 1px solid var(--matterPlaceholder);
    &::placeholder {
        color: var(--matterPlaceholder);
      }
    }

}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  margin: 20px 0;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
  margin: 0;
}

.termsText {
  @apply --marketplaceModalHelperText;
  margin: 0;
  text-decoration: none;
  color: #B2B2B2;
}

.termsLink {
  @apply --marketplaceModalHelperLink;
  color: #20503f;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.titleForm {
  font-size: 36px;
  font-weight: var(--fontWeightBold);
  line-height: 42px;
  color: #0F0F0F;
  margin: 0 0 30px 0;
}

.singUpFormContainer {
  margin-bottom: 20px;
}
.submitButton {
  margin: 20px 0;
  min-height: 48px;
}
.termsAndConditions {
  user-select: none;
  /*max-width: 176px;*/
  display: flex;
}
.termsChecked {
  margin: 0;
  height: 16px;
  width: 16px;
}
.termsCheckedText span {
  margin-right: 3px;
}

