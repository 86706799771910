.root {
  position: relative;
  width: 100%;
  height: 100%;
}

.track {
  /* Position */
  position: absolute;
  top: 50%;
  margin-top: -4px;
  margin-left: -10px;

  /* Layout */
  width: 100%;
  /*height: 4px;*/
  height: 4px;
  padding: 0;

  box-sizing: content-box;
  background-color: var(--matterColorNegative);
  /*background-color: #f1b0a9;*/
  border-radius: 4px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}
.trackC {
  /* Position */
  position: absolute;
  top: 50%;
  margin-top: -4px;
  margin-left: -10px;

  /* Layout */
  width: 100%;
  height: 4px;
  /*height: 8px;*/
  padding: 0 10px;

  box-sizing: content-box;
  /*background-color: var(--matterColorNegative);*/
  background-color: #E1E1E1;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.range {
  position: absolute;
  top: 50%;
  margin-top: -4px;
  /*height: 4px;*/
  height: 4px;
  background-color: #4A4A4A;
  /*background-color: var(--marketplaceColor);*/
}
.rangeC {
  position: absolute;
  top: 50%;
  margin-top: -4px;
  height: 4px;
  /*height: 8px;*/
  background-color: #4A4A4A;
  /*background-color: var(--marketplaceColor);*/
}
