@import '../../styles/propertySets.css';

.marginContext {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  padding-left: 0;

  &:hover {
    color: var(--marketplaceColor);
    /*color: var(--matterColorDark);*/
  }
}




