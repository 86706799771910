@import '../../styles/propertySets.css';

.root {
  display: inline-block;

  &:last-of-type {
    padding-right: 0;
  }
}

.menuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: 5px 18px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 5px;
  width: 100%;

  @media (--viewportSmall) {
    width: auto;
  }

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }

   & svg {
     width: 20px;
     height: 20px;
     margin-right: 8px;
   }
}

.menuLabelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;

  display: flex;
  align-items: center;
  padding: 5px 18px;
  background-color: #296151;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 5px;
  border: 1px solid #296151;

  width: 100%;

  @media (--viewportSmall) {
    width: auto;
  }

  &:hover,
  &:focus {
     border: 1px solid #239954;
     background-color: #239954;
  }

  & svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

.menuLabelSelected img {
  margin-right: 5px;
}

.menuContent {
  margin-top: 7px;
  padding-top: 13px;
  min-width: 260px !important;
  border-radius: 4px;
  right: 0;

  @media (--viewportSmall) {
    min-width: 300px !important;
  }
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton);
}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 7px);
  width: 6px;
  background-color: var(--matterColorDark);
}

.menuItem svg{
  max-width: 25px;
  max-height: 25px;
  margin-right: 15px;
}

/*.menuItem:hover svg{*/
/*  fill: var(--marketplaceColor);*/
/*}*/

.menuItem {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);

  /* Layout */
  position: relative;
  min-width: 260px;
  margin: 0;
  padding: 4px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;

  @media (--viewportSmall) {
    min-width: 300px;
  }

  &:focus{
    color: var(--matterColorDark);
  }
  &:hover {
     color: var(--marketplaceColor);
   }

  &:hover .menuItemBorder {
    width: 6px;
  }
}

.clearMenuItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterPlaceholder);
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  /* Layout */
  position: relative;
  min-width: 260px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;


  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }

  @media (--viewportSmall) {
    min-width: 300px;
  }

}

.icon {
  width: 20px;
}
