@import '../../styles/propertySets.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  /*@media (--viewportMedium) {*/
  position: fixed;
  top: 0;
  left: 0;
  /* We need to raise Topbar above .container */
  z-index: 51;
  /*}*/
}

.container {
  /* Layout */
  width: 100% - var(--topbarHeight);
  flex-grow: 1;
  margin-bottom: 20px;
  flex-direction: column;
  display: flex;

@media (--viewportLargeWithPaddingsSearch) {
  flex-direction: row;
  position: relative;
  padding-top: var(--topbarHeightDesktop);
  min-height: calc(100vh - var(--topbarHeightDesktop));
}
}

.searchResultContainer {
  order: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  /*margin: 0 18px;*/
  padding: 2px 0 0 0;
  @media (--viewportMedium) {
    /*padding-top: 60px;*/
    padding: 60px 20px 0 20px;
    /*margin: 60px 25px 0 15px;*/
    margin: 0 auto;
  }
  @media (min-width: 840px) {
    padding: 60px 0 0 0;
    max-width: 820px;
    width: 820px;
  }
  @media (--viewportLargeWithPaddingsSearch){
    order: 0;
    margin: 0 auto 0 40px;
  }
  /*@media (min-width: 1917px){*/
  /*  margin: 0 40px 0 auto;*/
  /*}*/
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  display: none;

@media (--viewportMedium) {
  display: flex;
  padding: 0 24px;
  margin-bottom: 21px;
}

@media (--viewportLargeWithPaddings) {
  padding: 0 36px 0 5px;
}
}

.searchFiltersMobile {
  padding: 16px 16px 16px 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

@media (--viewportLarge) {
  padding: 0;
  /*padding: 0 36px 36px 36px;*/
}
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  margin-top: 60px;
  /*@media (--viewportMedium) {*/
  /**
   * .container is using flexbox,
   * This specifies that mapPanel is taking 34% from the viewport width
   */
  flex-basis: 50%;

  /* Own layout settings */
  display: flex;
  height: 100%;
/*}*/

  @media (--viewportLarge) {
    margin-top: 100px;
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  /*height: 100vh;*/

  /*@media (--viewportMedium) {*/
  /* Map is fixed so that it doesn't scroll along search results */
  position: block;
  top: var(--topbarHeightDesktop);
  right: 0;

  /* Fixed content needs width relative to viewport */
  /*width: 100vw;*/

  height: 499px;
/*}*/

  @media (--viewportLargeWithPaddingsSearch) {
    position: fixed;
    top: 160px;
    /*padding-top: 60px;*/
    height: calc(100vh - var(--topbarHeightDesktop));
    width: calc(100vw - 900px);
  }
  /*@media (min-width: 1917px){*/
  /*  width: 37.7vw;*/
  /*}*/
}

.wrapperFooter {
  & div > div > div > div p {
      font-family: var(--fontFamily);
      max-width: 100%;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.06em;
      padding: 0;
    }
  & :global(#organization) {
      margin-right: 0;
      padding-bottom: 10px;
    }
}
