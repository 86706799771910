@import '../../styles/propertySets.css';

.root {

}

.title {
  @apply --marketplaceDefaultFontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0 0 15px 0;
  color: #101010;
  font-size: 20px;
  line-height: 23px;
  @media (--viewportMedium) {
    padding: 0 30px;
  }
  @media (--viewportLargeWithPaddingsSearch) {
    padding: 0;
  }
}

.locations {
  display: flex;
  flex-direction: column;
  position: relative;

  @media (--viewportMedium) {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 30px;
    & div:nth-child(3n) {
        margin-right: 0;
      }
  }
  @media (--viewportLargeWithPaddingsSearch) {
    padding: 0;
  }
}

.location {
  width: 100%;
  margin-top: 25px;
  position: relative;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location:hover .shareLike {
  opacity: 1;
  visibility: visible;
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.shareLike {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 84px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  z-index: 9;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%);
  /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%);
    /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

.sliderPrevArrow,
.sliderNextArrow {
  cursor: pointer;
  position: absolute;
  width: 42px;
  height: 45px;
  background: var(--matterColorLight);
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  outline: none;
  box-shadow: var(--boxShadowSliderArrows);
  border-radius: var(--borderRadiusCircle);
}

.sliderPrevArrow {
  left: -30px;

  @media (--viewportLarge) {
    left: -50px;
  }
}

.sliderNextArrow {
  right: -30px;

  @media (--viewportLarge) {
    right: -50px;
  }
}

.sliderNextArrow:after,
.sliderPrevArrow:after {
  content: '';
  position: absolute;
  border: 2px solid var(--marketplaceColor);
  border-width: 2px 2px 0 0;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
}

.sliderNextArrow:after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.sliderPrevArrow:after {
  transform: translate(-50%, -50%) rotate(-135deg);
}

.cardItem {
  width: 100%;
  margin-right: 20px;
  margin-bottom: 30px;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
  border-radius: 10px;
  @media (--viewportMedium) {
    width: 400px;
    max-width: 400px;
  }
}


.slickTrack {
  width: 100%;
}
