@import '../../styles/propertySets.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .box,
  &:focus + label .box,
  &:checked + label .box {
    stroke: var(--marketplaceColor);
  }

  /* successColor version */
  &:hover + label .boxSuccess,
  &:focus + label .boxSuccess,
  &:checked + label .boxSuccess {
    stroke: var(--successColor);
  }

  /* Display the "check" when checked */
  &:checked + label .checked {
     display: inline;
     fill: var(--matterTomato);
     /*stroke:var(--matterTomato);*/
     stroke: black;
  }

  &:checked + label .checkedBorder {
     display: inline;
     fill: var(--matterTomato);
   }

  /* Display the "check" when checked */
  &:checked + label .checkedSuccess {
    display: inline;
    fill: var(--successColor);
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .text,
  &:hover + label .text,
  &:checked + label .text {
    color: var(--matterColorDark);
  }
}

.label {
  /*width: 24px;*/
  display: flex;
  padding: 0;
}

.checkboxWrapper {
  /* This should follow line-height */
  /* height: 32px; */
  margin-top: 0;
  margin-right: 0;
  align-self: baseline;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  position: relative;
  top: 3px;
}
.checkedBorder {
  /*stroke: none;*/
  fill: white;
}
.checked {
  fill: none;
  stroke: black;
}
.checkedSuccess {
  fill: var(--matterColor);
}

.boxSuccess,
.box {
  stroke: black;
}

.textRoot {
  @apply --marketplaceListingAttributeFontStyles;
  color: var(--matterColor);
  /* margin-top: -1px;
  margin-bottom: 1px; */
  cursor: pointer;
  line-height: 1.5;
}

@media screen and (max-width: 550px) {
  .textRoot {
    display: none;
  }
}

.logged {
  pointer-events: all;
}

.unLogged {
  /*pointer-events: none;*/
}
