@import '../../styles/propertySets.css';


.section {
  padding: 22px 30px 68px;

  @media (--viewportMedium) {
    width: 880px;
    margin: 0 auto;
    padding: 0;
  }

  @media (--viewportLargeWithPaddingsSearch) {
    width: 1260px;
  }
}

.locationRelated {
      justify-content: center;
    @media (--viewportMedium) {
      justify-content: center;
      margin-right: 0;
    }
    @media (--viewportLargeWithPaddingsSearch) {
      justify-content: flex-start;
      & div:nth-child(2n) {
        margin-right: 0;
      }
    }
}
.classesItem {
  max-width: 610px;
  height: 340px;
  max-height: 340px;

  & > div:nth-child(1) {
    height: 238px;
  }

  /*& a {*/
  /*    height: 23px !important;*/
  /*  }*/

  @media (--viewportMedium) {
    width: 610px !important;
    justify-content: center;
    margin-right: 0;
  }
  @media (--viewportLargeWithPaddingsSearch) {
    margin-right: 23px ;
  }
}

.footerMargin {
  margin-bottom: 116px;
}
.imgContentStyle {
  margin-top: 88px;
  height: 400px;
  margin-bottom: 60px;
}
.imgText {
  position: absolute;
  width: 100%;
  top:50%;
  left:50%;
  color: var(--matterColorLight);
  transform:translate(-50%, -50%);
  /*text-align: center;*/

  @media (--viewportMedium) {
    width: 820px;
  }

  @media (--viewportLargeWithPaddingsSearch) {
    width: 1280px;
  }

  & h2 {
      font-size: 36px;
      line-height: 42px;
      font-weight: var(--fontWeightBold);
      letter-spacing: -1px;
      margin: 0;
      padding: 0;
    }
  & h4 {
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      font-weight: var(--fontWeightBold);
    }
}

.title {
  padding: 24px;

  @media (--viewportLarge) {
    max-width: 100%;
    padding: 24px 36px 24px 36px;
  }
}

.sectionTitle {
  margin-bottom: 28px;
}

.favoritesPageContent {
  margin: 40px 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: 60px 24px 60px 24px;
  }

  @media (--viewportLarg  e) {
    margin: 40px 80px 110px 80px;
  }
}

.anotherSection {
  max-width: 100%;
  padding: 24px 36px 24px 36px;
}

.topTitle {
  color: #101010;
  font-size: 30px;
  line-height: 35px;
  font-weight: var(--fontWeightBold);
  margin-top: 37px;
  margin-bottom: 31px;
@media (--viewportMedium) {
  padding: 0 30px;
}
@media (--viewportLargeWithPaddingsSearch) {
  padding: 0;
}
}
