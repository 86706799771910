@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    /*justify-content: space-between;*/
  }
}

.password {
  @apply --marketplaceModalPasswordMargins;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  margin-top: 20px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.recoveryLink {
  @apply --marketplaceModalHelperText;
}

.recoveryLinkInfo {
  @apply --marketplaceModalHelperLink;

}
.titleForm {
  font-size: 36px;
  font-weight: var(--fontWeightBold);
  line-height: 42px;
  color: #0F0F0F;
  margin: 0 0 30px 0;
}

.inputForm {
  margin-bottom: 20px;
  & input {
      font-weight: var(--fontWeightRegular);
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.04em;
      padding: 14px 15px;
      border-radius: 5px;
      border: 1px solid var(--matterPlaceholder);
      /*border-bottom-width: 0;*/
    }
}

.submitButton {
  min-height: 48px;
}
