.itineraryHolder {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.accordionHolder {
  padding-top: 10px;
  flex: 0 0 100%;
  max-width: 100%;

  @media (--viewportMedium) {
    flex: 0 0 40%;
    width: 40%;
    padding-left: 16px;
  }
}

.itinerarySectionText {
  font-size: 18px;
  line-height: 30px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: -0.04em;
  margin: 0;
  padding: 10px 29px 25px 20px;
  @media (--viewportMedium) {
    letter-spacing: -0.04em;
    font-size: 18px;
    line-height: 26px;
    padding: 21px 20px 25px;
  }
}
.itinerarySectionTextMobile {
  display: block;
  @media (--viewportMedium) {
   display: none;
  }
}
.itinerarySectionTextDesktop {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}
.reedMore {
  font-weight: var(--fontWeightBold);
  color: var(--matterTomato);
  padding: 21px 20px 25px 5px;
  cursor: pointer;
}
.mapHolder {
  /*flex: 0 0 100%;*/
  width: 100%;
  height: 372px;
  object-fit: cover;
  /*padding-top: 10px;*/

  @media (--viewportMedium) {
    height: 312px;
    /*flex: 0 0 55%;*/
    /*max-width: 55%;*/
    /*padding: 0;*/
  }

  /*& :global(.gm-style-iw-t){*/
    /*display: none;*/
  /*}*/
}

.customItem {
  border: 1px solid #979797;
  width: 100%;
  height: 248px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.bottomGallery {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomGalleryItem {
  max-width: 100px;
  margin-right: 5px;
  border: 1px solid #979797;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 80px;
  height: 80px;
}

.bottomGalleryItem img {
  width: 100%;
}

.GalleryNavHolder {
  position: relative;
}

.GalleryNavPrev {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  border: 0;
  outline: none;
}

.GalleryNavNext {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  border: 0;
  outline: none;
}

.GalleryNavPrev:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid var(--matterColor);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  border-width: 2px 0 0 2px;
}

.GalleryNavNext:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid var(--matterColor);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  border-width: 2px 2px 0 0;
}

.itineraryListItem {
  height: 190px;
  display: flex;
  margin-bottom: 25px;
  max-width: 827px;

  @media screen and (max-width: 550px) {
    flex-direction: column;
    height: auto;
  }
}

.itineraryItemNumber {
  background-color: #9e9e9e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 17px;
  /* gap: 20px; */
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.itineraryContentContainer {
  display: flex;
  background-color: #fff;
  padding: 20px 0 20px 20px;
  width: 100%;

  @media screen and (max-width: 550px) {
    flex-direction: column;
    align-items: center;
  }
}

.imageContainer {
  width: 190px;
  min-width: 190px;
  height: 150px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 15px;

  @media screen and (max-width: 550px) {
    margin-bottom: 10px;
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.itineraryItemNumberContainer {
  display: flex;
  align-items: center;
  margin-right: 10px;

  @media screen and (max-width: 550px) {
    display: flex;
    justify-content: center;
  }
}

.eyeIconContainer {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0px 43px 0px 28px;
  cursor: pointer;

  @media screen and (max-width: 550px) {
    display: flex;
    justify-content: center;
  }
}

.itineraryTitle {
  margin: 0;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}

.noImgContainer {
  position: relative;
  width: 190px;
  height: 150px;
  border-radius: 5px;
  margin-right: 15px;
}

.itineraryName {
  display: block;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  color: #4a4a4a;

  display: -webkit-box;
  max-width: 530px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.itineraryDescription {
  margin-top: 5px;
  line-height: 18px;
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 700;

  display: -webkit-box;
  max-width: 530px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  & p {
    margin: 0;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.itemOpen {
  height: auto;
}

.showDesc {
  display: block;
}
