@import '../../styles/propertySets.css';

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--matterColorBright);
}

.filtersWrapper {
  /*padding-top: 20px;*/
  /*@media (--viewportMedium) {*/
  /*  padding-top: 0;*/
  /*}*/
  /*padding: 110px 16px 0 30px;*/
}

.footer {
  display: flex;
}

.customFilters {
  display: flex;
  /*justify-content: space-between;*/
  flex-wrap: wrap;
  margin-top: 20px;
}

.customFilters > div {
  width: 100%;
  border: 0;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.customFilters > div:first-child {

  @media (--viewportMedium) {
    /*border-right: 1px solid black;*/
    padding-right: 40px;
  }
}

.resetAllButton {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightBold);
  color: var(--matterPlaceholder);
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  /* Layout */
  margin: 0 auto 0 0;

  /* Override button styles */
  outline: none;

&:focus,
&:hover {
   text-decoration: none;
   color: var(--matterColor);
 }
}

.cancelButton {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterPlaceholder);
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  /* Layout */
  margin: 0 24px 0 24px;

  /* Override button styles */
  outline: none;

&:focus,
&:hover {
   text-decoration: none;
   color: var(--matterColor);
 }
}

.applyButton {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  /* Layout */
  margin: 0;
  color: var(--matterTomato);
  /* Override button styles */
  outline: none;

&:focus,
&:hover {
   text-decoration: none;
   color: var(--marketplaceColorDark);
 }
}
