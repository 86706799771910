@import '../../styles/propertySets.css';

.root {
  /*border-top-style: solid;*/
  /*border-top-width: 1px;*/
  /*border-top-color: var(--matterColorNegative);*/
  z-index: -1;

  width: 100%;
  padding: 24px 24px 0 24px;
  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 30px 0;
    border-top: none;
  }
}

.currencySelect{
  border: none;
  padding-left: 10px;
  margin-top: 10px;
  padding-bottom: 5px;
  margin-right: 20px;
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 30px;
  }

  @media (--viewportLarge) {
    padding-top: 31px;

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
}

/* Footer links */
.links {
  /* Layout */
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  @media (--viewportMedium) {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  border-bottom: 1px solid #D7D7D7;
  margin-right: 24px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    border-bottom: 0;
    flex-basis: 220px;
    display: block;
    order: 1;
    margin-right: 12px;
  }

  @media (--viewportLarge) {
    flex-basis: 240px;
    margin-right: 57px;
  }

  @media (--viewportLargeWithPaddings) {
    flex-basis: 250px;
    margin-right: 12px;
  }
}
.lineMobile {
  width: 100%;
  background: #D7D7D7;
  margin: 0 26px 0 13px;
  display: block;
  @media (--viewportMedium) {
    display: none;
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 32px;
}

.organizationDescription {
  font-size: 13px;
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
  color: #4A4A4A;
  font-family: "Inter", sans-serif;
  max-width: 170px;
  padding-left: 4px;
  letter-spacing: -0.1px;
  @media (--viewportMedium) {
    font-family: var(--fontFamily);
    padding-left: 0;
    font-size: 16px;
    line-height: 30px;
    max-width: 218px;
    width: 218px;
    line-height: 30px;
  }
}

.organizationCopyrightSmall {
  display: none;
@media (--viewportMedium) {
  display: block;
}
}

.organizationCopyright {
  @apply --marketplaceTinyFontStyles;
  color: #B2B2B2;
  transition: var(--transitionStyleButton);
  text-align: center;
  /* Tell parent component how much space this one prefers */
  /*flex-basis: 172px;*/

  margin-top: 0;
  margin-bottom: 0;

&:hover {
   color: var(--marketplaceColor);
   text-decoration: none;
 }
}
.privacySmall {
  @apply --marketplaceTinyFontStyles;
  color: #B2B2B2;
  display: block;
  line-height: 24px;
  transition: var(--transitionStyleButton);
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    display: none;
  }
}
.copyrightLink {
  @apply --marketplaceTinyFontStyles;
  color: #B2B2B2;
  display: none;
  transition: var(--transitionStyleButton);
  text-align: center;
  margin-top: 0;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
  @media (--viewportMedium) {
    display: block;
  }
  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.copyrightLinkSmall {
  @apply --marketplaceTinyFontStyles;
  display: block;
  color: #B2B2B2;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  margin-bottom: 18px;
  transition: var(--transitionStyleButton);
  margin-top: 0;
@media (--viewportMedium) {
  display: none;
}
&:hover {
   color: var(--marketplaceColor);
   text-decoration: none;
 }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  flex-basis: 116px;
  margin-right: 24px;
  margin-bottom: 12px;
  border-bottom: 1px solid #D7D7D7;

  @media (--viewportMedium) {
    flex-basis: 153px;
    border-bottom: 0;
    padding-top: 2px;
    margin-right: 12px;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.titleList {
  @apply --marketplaceH4FontStyles;
  display: none;
  margin-bottom: 5px;
  margin-top: 0;
  color: var(--matterColor);
  line-height: 30px;
  font-weight: var(--fontWeightBold);
  transition: var(--transitionStyleButton);
  @media (--viewportMedium) {
    display: block;
  }
}
.listItem {
  min-height: 24px;
  line-height: 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    line-height: 30px;
  }
}

.link {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  line-height: 24px;
  font-family: "Inter", sans-serif;
  font-weight: var(--fontWeightRegular);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    font-family: var(--fontFamily);
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 153px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  display: none;
  position: relative;
  order: 4;
  /*flex-basis: 165px;*/
  flex-basis: 185px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: block;
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 109px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
    flex: 0 0 14%;
  }
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;
}
.someLinks {
  padding-top: 10px;
  /* position: absolute;
  bottom: -15px; */
  & a {
      margin-right: 20px;
    }
}
.someLinksSmall {
  display: flex;
  margin-top: 0;
  @media (--viewportMedium) {
    display: none;
  }
}

.legalLink,
.privacy,
.terms {
  /* Font */
  /*@apply --marketplaceTinyFontStyles;*/
  color: var(--matterColor);
  font-size: 16px;
  font-weight: var(--fontWeightRegular);
  line-height: 30px;
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 200px;
  display: flex;
  flex-direction: column;
}

.privacy {
  margin-right: 24px;
}

.languageCurrency {
  display: flex;
  align-items: center;
}

.menuLabel {
  position: relative;
}

.menuLabel:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid black;
  border-width: 0 0 2px 2px;
  top: 50%;
  right: -11px;
  transform: rotate(-45deg) translateY(-45%);
}

.languageCurrencyMenu {
  margin-right: 10px;
  height: auto;
}

.languageCurrencyMenuMobile {
  display: flex;
  height: 20px;
  margin-bottom: 19px;
  margin-left: 88px;
}

.languageCurrencyMenuMobileFot {
  margin-top: 15px;
}

.hostLink {
  border: none;
  padding: 0;
  line-height: 30px;
  font-weight: var(--fontWeightRegular);
  color: var(--matterColor);
  margin-top: 5px;
  font-size: 16px;
  &:hover {
     color: var(--marketplaceColor);
     text-decoration: none;
   }
}

.topLastEl {
  margin-top: 6px;
  display: block;
  @media (--viewportMedium) {
    margin-top: 0;
    display: none;
  }
}
/*.customShow {*/
/*  display: block;*/
/*  @media (--viewportMedium) {*/
/*    display: block;*/
/*  }*/
/*}*/

.titleLink {
  color: var(--matterColor);

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}
